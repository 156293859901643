import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import smoothscroll from "smoothscroll-polyfill"
import { setActiveFilters } from "state/actions/filters";
import { isEmpty } from "helpers"

class FilterCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSetDisabled = this.handleSetDisabled.bind(this);
  }

  componentDidMount() {
    this.setupValues();
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.filteredItems !== this.props.filteredItems) {
      this.handleCheckbox();
    }

    if (prevProps.filteredTags !== this.props.filteredTags) {
      this.handleSetDisabled();
    }
  }

  handleCheckbox(e) {
    const { activeFilters, item: { value } } = this.props;

    if (!isEmpty(e)) {
      this.moveCheckboxesPosition();
    }

    if (!isEmpty(e) && e.target.checked) {
      let newFilters = activeFilters.concat([value]);
      this.handleSetDisabled();
      this.props.setActiveFilters(newFilters);
      return
    } else if (!isEmpty(e) && !e.target.checked) {
      let newFilters = activeFilters.filter( filters => filters !== value);
      this.handleSetDisabled();
      this.props.setActiveFilters(newFilters);
      return
    } else if (activeFilters > 0) {
      this.handleSetDisabled();
      return
    }
  }

  handleSetDisabled() {
    const { filteredTags, item: { value } } = this.props;

    if (filteredTags.indexOf(value) < 0) {
      this.setState({
        isDisabled: true
      });
    } else {
      this.setState({
        isDisabled: false
      });
    }
  }


  /**
   * @description When the checkboxes are activated scroll to the top of the section
   *
   * @return void
   */
  moveCheckboxesPosition = () => {
    smoothscroll.polyfill();
    if (!isEmpty(this.itemsScrollTop) && !isEmpty(this.headerHeight)) {
      window.scrollTo({
        top: (this.itemsScrollTop - this.headerHeight) - 75,
        behavior: 'smooth',
      });

    }
  };

  /**
   * @description Get our initial values
   *
   * @return void
   */
  setupValues = () => {
    const agendaItems = window.document.querySelector('.AgendaItems');
    const header = window.document.querySelector('.Header');

    if (!isEmpty(agendaItems) && !isEmpty(header)) {
      this.itemsScrollTop = agendaItems.getBoundingClientRect().top + window.pageYOffset;
      this.headerHeight = header.getBoundingClientRect().height;
    }
  };

  render() {
    let { item } = this.props;

    return (
      <label className={this.state.isDisabled ? "checkbox-is-disabled" : ""}>
        <input disabled={this.state.isDisabled} type="checkbox" value={item.value} onChange={this.handleCheckbox} />
        {item.label}
      </label>
    );
  }
}

FilterCheckbox.propTypes = {
  activeFilters: PropTypes.array,
  filteredItems: PropTypes.array,
  filteredTags: PropTypes.array,
  item: PropTypes.object
}

const stateToProps = state => ({
  activeFilters: state.filters.activeFilters,
  filteredItems: state.filters.filteredItems,
  filteredTags: state.filters.filteredTags
});

const dispatchToProps = {
  setActiveFilters
};

const Connected = connect(stateToProps, dispatchToProps)(
  FilterCheckbox
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = FilterCheckbox;
export default Connected;
