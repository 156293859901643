import React from "react"

const WYSIWYG = ({ content, overrideStyles }) => {
  const styles = {
    color: "inherit",

    ".text-align-left": {
      textAlign: "left",
    },
    ".text-align-right": {
      textAlign: "right",
    },
    ".text-align-center": {
      textAlign: "center",
    },
    ".text-align-justify": {
      textAlign: "justify",
    },
    ".align-left": {
      float: "left",
    },
    ".align-right": {
      float: "right",
    },
    ".align-center": {
      display: "block",
      marginRight: "auto",
      marginLeft: "auto",
    },

    h1: {
      mb: ["spacing4", null, null, "spacing4"],
    },
    h2: {
      mb: ["spacing3", null, null, "spacing3"],
    },
    h3: {
      mb: ["spacing2", null, null, "spacing3"],
    },
    h4: {
      mb: ["spacing2", null, null, "spacing2"],
    },
    h5: {
      mb: ["spacing2", null, null, "spacing3"],
    },
    h6: {
      mb: ["spacing1", null, null, "spacing2"],
    },

    "& + .WYSIWYG": {
      mt: "spacing4",
    },
  }
  return (
    <div
      className="WYSIWYG"
      sx={{ ...styles, ...overrideStyles }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

WYSIWYG.propTypes = {}

export default WYSIWYG