import React from "react"

const MinusIcon = () => (
  <svg className="MinusIcon" width="20" height="2" viewBox="0 0 20 2" fill="none">
    <line y1="1" x2="20" y2="1" stroke="white" strokeWidth="2"/>
  </svg>
)

MinusIcon.propTypes = {
}

export default MinusIcon
