import React from "react"
import PropTypes from "prop-types"

import {
  AccordionSectionParagraph,
  AuthorsParagraph,
  BlockParagraph,
  CardParagraph,
  ColumnsParagraph,
  HeroH1HtmlCtaParagraph,
  HeroTitleImageParagraph,
  HTMLParagraph,
  ImageContentParagraph,
  LatestArticlesParagraph,
  LogoSetSectionParagraph,
  MapParagraph,
  MarketoFormParagraph,
  SecuritySectionParagraph,
  PricingSectionParagraph,
  PricingItemParagraph,
  ReadMoreSectionParagraph,
  SpeakersParagraph,
  VidyardParagraph
} from "components"

const paragraphsMapping = {
  paragraph__accordion_section: AccordionSectionParagraph,
  paragraph__block: BlockParagraph,
  paragraph__card: CardParagraph,
  paragraph__header_columns_cta: ColumnsParagraph,
  paragraph__hero_h1_html_cta: HeroH1HtmlCtaParagraph,
  paragraph__hero_title_image: HeroTitleImageParagraph,
  paragraph__html: HTMLParagraph,
  paragraph__image_content: ImageContentParagraph,
  paragraph__security_latest_articles: LatestArticlesParagraph,
  paragraph__logo_set_section: LogoSetSectionParagraph,
  paragraph__map: MapParagraph,
  paragraph__marketo_form: MarketoFormParagraph,
  paragraph__security_blog_authors: AuthorsParagraph,
  paragraph__security_section: SecuritySectionParagraph,
  paragraph__pricing_section: PricingSectionParagraph,
  paragraph__pricing_item: PricingItemParagraph,
  paragraph__read_more_section: ReadMoreSectionParagraph,
  paragraph__speakers: SpeakersParagraph,
  paragraph__vidyard: VidyardParagraph
}

const Paragraph = (props, id) => {
  const { type } = props;
  if (!paragraphsMapping[type]) {
    return (
      <p style={{ color: `red` }}>Missing Paragraph type {type}. Cannot show paragraph n°{id}.</p>
    );
  }

  const Component = paragraphsMapping[type];

  return <Component key={id} id={id} {...props} node={props} />
}

Paragraph.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}

export default Paragraph
