import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { ModalPhotoBio, ModalTrigger } from "components"
import "./Sponsor.scss";

const Sponsor = ({ data }) => {
  if (!data.relationships.field_media_image) {
    return null
  }

  const modalContent = <ModalPhotoBio
    image={<Img fluid={data.relationships.field_media_image.relationships.field_media_image.localFile.childImageSharp.fluid} />}
    bio={data.field_body && data.field_body.value} />;

  return (
    <ModalTrigger classes="Logo-set-item" content={modalContent}>
      <div className="Logo-set-item-image-wrapper">
        <Img fluid={data.relationships.field_media_image.relationships.field_media_image.localFile.childImageSharp.fluid} />
      </div>
    </ModalTrigger>
  )
}

Sponsor.propTypes = {
  description: PropTypes.string,
  img: PropTypes.object,
}

export default Sponsor
