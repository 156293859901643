import React, { Component } from "react"
import Cookies from 'js-cookie'
import { getQueryParam } from "helpers"

class SetRefIDCookie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refState: ""
    };
  }

  componentDidMount() {
    this.getRefID();
  }

  getRefID = () => {
    const refCookie = this.checkCookie();
    if (refCookie) {
      return
    } else if (window.location.search !== "") {
      const refId = getQueryParam('RefID', window.location.search);

      this.setState({
        refState: refId ? refId : ""
      }, this.setCookie)
    }

    return
  }

  checkCookie = () => {
    const refCookie = Cookies.get('_okta_cvent');

    if (refCookie) {
      return refCookie
    }
  }

  setCookie = () => {
    const { refState } = this.state;

    if (refState && refState !== "") {
      Cookies.set('_okta_cvent', refState);
    }
  }

  render() {
    return (
      <div />
    )
  }
}

SetRefIDCookie.propTypes = {
}

export default SetRefIDCookie
