import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { AccordionSectionItem, Container, SectionHeading } from "components"
import "./AccordionSectionParagraph.scss"

const AccordionSectionParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  return (
    <Container>
      <div className="Accordion-section-paragraph">
        <div className="Accordion-section-paragraph_title">
          <SectionHeading data={data} />
        </div>
        <div className="Accordion-section-paragraph_items">
          {data.relationships && data.relationships.field_accordion_items.map((item, i) => {
            return <AccordionSectionItem data={item} key={i} />
          })}
        </div>
      </div>
    </Container>
  )
}

export const fragment = graphql`
  fragment AccordionSectionParagraph on paragraph__accordion_section {
    id
    drupal_internal__revision_id
    field_header_tag
    field_title
    relationships {
      field_accordion_items {
        field_title
        field_header_tag
        field_body {
          value
        }
      }
    }
  }
`

AccordionSectionParagraph.propTypes = {
  id: PropTypes.string.isRequired
}

export default AccordionSectionParagraph
