import React, { Component } from "react";
import PropTypes from "prop-types";
import { AgendaTime } from "components"
import "./AgendaDay.scss"

class AgendaDay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  componentDidMount() {
    this.handleInit();
  }

  componentDidUpdate(prevProps, prevState) {
   if (prevProps.data !== this.props.data) {
      this.handleInit();
    }
  }

  handleInit = () => {
    this.handleSortDayByTime(this.props.data.items);
  }

  handleSortDayByTime = (day) => {
    let times = {};

    day.forEach((time) => {
      if (!times.hasOwnProperty(time.start_time)) {
        times[time.start_time] = [time];
      } else {
        times[time.start_time].push(time);
      }
    })

    this.handleUpdateItems(times);
  }

  handleUpdateItems = (data) => {
    const keys = Object.keys(data);
    let updatedItems = [];

    keys.forEach((key) => {
      let tempObject = {
        time: key,
        items: data[key]
      }

      updatedItems.push(tempObject);
    })

    this.setState({
      items: updatedItems
    });
  }

  render() {
    const { items } = this.state;
    const { data } = this.props;

    return (
      <div className="AgendaDay">
        <h5 className="AgendaDay-title">{data.day}</h5>
        {
          items.map((time, i) => {
            return <AgendaTime data={time} key={i} />
          })
        }
      </div>
    );
  }
}

AgendaDay.propTypes = {
  activeFilters: PropTypes.array,
  filteredItems: PropTypes.array,
  filteredTags: PropTypes.array,
  noMatchMessage: PropTypes.string,
  searchTerms: PropTypes.string,
}

export default AgendaDay
