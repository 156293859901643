/***********
 * IMPORTS *
 ***********/
import { createReducer } from "redux-blower";

/***********
 * ACTIONS *
 ***********/
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_FILTERED_ITEMS = "SET_FILTERED_ITEMS";
export const SET_FILTERED_TAGS = "SET_FILTERED_TAGS";
export const SET_ITEMS_ARE_DOWNLOADED = "SET_ITEMS_ARE_DOWNLOADED";
export const UPDATE_FILTER_OBJECTS = "UPDATE_FILTER_OBJECTS";

/***********
 * REDUCER *
 ***********/
export default createReducer({
  initialState: {
    activeFilters: [],
    filteredItems: [],
    filteredTags: [],
    itemsAreDownloaded: false
  },

  listenTo: {
    [SET_ACTIVE_FILTERS]: (state, action) => ({
      ...state,
      activeFilters: action.payload
    }),

    [UPDATE_FILTER_OBJECTS]: (state, action) => ({
      ...state,
      activeFilters: action.payload
    }),

    [SET_ITEMS_ARE_DOWNLOADED]: (state, action) => ({
      ...state,
      itemsAreDownloaded: action.payload
    }),

    [SET_FILTERED_ITEMS]: (state, action) => ({
      ...state,
      filteredItems: action.payload
    }),

    [SET_FILTERED_TAGS]: (state, action) => ({
      ...state,
      filteredTags: action.payload
    })
  }
});

/*******************
 * ACTION CREATORS *
 *******************/
export function setActiveFilters(filters) {
  return {
    type: SET_ACTIVE_FILTERS,
    payload: filters
  };
}

export function setFilteredItems(filters) {
  return {
    type: SET_FILTERED_ITEMS,
    payload: filters
  };
}

export function setFilteredTags(filters) {
  return {
    type: SET_FILTERED_TAGS,
    payload: filters
  };
}

export function updateFilterObjects(activeFilters, parentName, newValue) {
  const newFilters = activeFilters.forEach((item) => {
    console.log("in loop");
    if (item.name === parentName) {
      console.log("Found match");
      const itemIndex = activeFilters.indexOf(item);
      let newObject = Object.assign({}, item, {
          name: parentName,
          options: [newValue]
      });
      let filters = [...activeFilters];
      filters.splice(itemIndex, 1, newObject);
      console.log("Filters; ", filters);
      return filters
    }
  });

  console.log("newFilters: ", newFilters);

  return {
    type: UPDATE_FILTER_OBJECTS,
    payload: newFilters
  };
}

export function setItemsAreDownloaded() {
  return {
    type: SET_ITEMS_ARE_DOWNLOADED,
    payload: true
  };
}


// export function initEmptyFilterObject(activeFilters, fullFilterObject) {
//   const newName = fullFilterObject.name;
//   const newOptions = fullFilterObject.options;
//   const thisObj = {
//     name: fullFilterObject.name,
//     options: []
//   };

//   activeFilters.push(thisObj);

//   return {
//     type: UPDATE_FILTER_OBJECTS,
//     payload: filters
//   };
// }
