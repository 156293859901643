import React, { Component } from "react"
import PropTypes from "prop-types"
import { slugify } from "helpers"

class FilterDropdownItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };

    this.handleSetDisabled = this.handleSetDisabled.bind(this);
  }

  componentDidMount() {
    this.handleSetDisabled();
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.filteredTags !== this.props.filteredTags) {
      this.handleSetDisabled();
    }
  }

  handleSetDisabled() {
    const { filteredTags, item: { label } } = this.props;

    const cleanedTag = slugify(label.toLowerCase());
    if (filteredTags.indexOf(cleanedTag) < 0) {
      this.setState({
        isDisabled: true
      });
    } else {
      this.setState({
        isDisabled: false
      });
    }
  }

  render() {
    const { item, itemParentName, setFilter } = this.props;
    const { isDisabled } = this.state;
    return (
      <div
        role="button"
        tabIndex={0}
        className={`Filter_Dropdown-option ${isDisabled ? "is-disabled" : ""}`}
        data-parentname={itemParentName}
        data-value={item.value}
        onKeyDown={setFilter}
        onClick={setFilter}
      >
        {item.label}
      </div>
    )
  }
}

FilterDropdownItem.propTypes = {
  item: PropTypes.object,
  itemParentName: PropTypes.string,
  setFilter: PropTypes.func
}

export default FilterDropdownItem
