import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet"
import { graphql } from "gatsby";
import { Form } from "components";

const MarketoFormParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  return (
    <div>
      <Helmet>
        <script src="//pages.okta.com/js/forms2/js/forms2.min.js" async />
      </Helmet>
      <Form formId={data.field_marketo_form_id} />
    </div>
  )
}

MarketoFormParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

 export const fragment = graphql`
  fragment MarketoFormParagraph on paragraph__marketo_form {
    drupal_internal__id
    field_marketo_form_id
  }
`


export default MarketoFormParagraph
