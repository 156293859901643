import propTypes from "prop-types"

const blogTeaserText = ({text, maxLength}) => {
  // Default.
  maxLength = maxLength || 150;

  const textStrippedHtml = text.replace(/(<([^>]+)>)/ig,"");
  let teaser = textStrippedHtml.substr(0, maxLength)

  if (textStrippedHtml.length > maxLength) {
    teaser = teaser.substring(0, teaser.lastIndexOf(" "))
  }

  if (teaser !== textStrippedHtml) {
    teaser = `${teaser}...`
  }

  return `<p>${teaser}</p>`
}

blogTeaserText.propTypes = {
  text: propTypes.string.isRequired,
  length: propTypes.number
}

export default blogTeaserText
