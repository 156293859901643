import React from "react"
import PropTypes from "prop-types"
import { ModalPhotoBio, ModalTrigger } from "components"

const AgendaSpeaker = ({ data }) => {
  if ((data.image && data.image.url) || data.bio) {
    const modalContent = <ModalPhotoBio
      imageURL={data.image ? data.image.url : ""}
      imageALT={data.image ? data.image.alt : ""}
      name={data.name}
      jobTitle={data.job_title ? data.job_title : ""}
      company={data.company ? data.company : ""}
      bio={data.bio ? data.bio : ""} />;

    return (
      <ModalTrigger
        classes="AgendaItem-info-speaker"
        content={modalContent}
        element="a">
        <p>
          <span className="AgendaItem-info-speaker-name">{data.name}</span>{data.job_title && `, ${data.job_title}`}{data.company && `, ${data.company}`}
        </p>
      </ModalTrigger>
    )
  }
  return (
    <p className="AgendaItem-info-speaker">
      <span>{data.name}</span>{data.job_title && `, ${data.job_title}`}{data.company && `, ${data.company}`}
    </p>
  )
}

AgendaSpeaker.propTypes = {
  data: PropTypes.object
}

export default AgendaSpeaker
