import React from "react"

import { Layout } from "components"

const _404 = () => (
  <Layout>
    <div className="main-content centered">
        <div className="Container ">
            <h1>404 Page Not Found</h1>
            <p>The web page or file you were trying to reach cannot be found. It may have been moved to a different location or removed because the information it contained is no longer valid.</p>
            <p>Common errors that can result in broken links include:</p>
            <ul>
                <li>Truncated URLs. Some email applications may break a URL due to line breaks. You may need to cut and paste a URL from your email application into your browser's location field.</li>
                <li>Case Sensitivity. URLs are case sensitive. "File.html" and "file.html" are not considered the same file by the server. Please verify that the URL is entered correctly as printed.</li>

            </ul>
            <p>Please <a href="mailto:info@okta.com">email</a> your feedback/comments.</p>
        </div>
    </div>
  </Layout>
)

export default _404
