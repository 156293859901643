import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Prism from "prismjs";
import "./CodeHighlighter.css";

const CodeHighlighter = ({ language, data }) => {
  const builtLanguage = `language-${language}`;

  useEffect(() => {
    Prism.highlightAll();
  }, [])

  if (!language) {
    return (
      <code>
        {data}
      </code>
    )
  }

  return (
    <pre className={builtLanguage}>
      <code className={builtLanguage}>
        {data}
      </code>
    </pre>
  )
}

CodeHighlighter.propTypes = {
  language: PropTypes.string,
  data: PropTypes.string,
}

export default CodeHighlighter
