import React from "react"
import { Container, SocialIcons } from "components";
import "./Footer.scss";

const Footer = () => (
  <div className="Footer">
    <Container>
      <div className="Footer-content">
          <ul className="Footer-links">
            <li><a href="https://sec.okta.com/rss.xml">Subscribe to RSS</a></li>
            <li><a href="https://trust.okta.com">trust.okta.com</a></li>
            <li><a href="https://sec.okta.com">sec.okta.com</a></li>
            <li><a href="https://okta.com">okta.com</a></li>
          </ul>
      </div>
    </Container>
  </div>
)

export default Footer
