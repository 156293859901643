import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Footer, Modal, Header, SetRefIDCookie, TopNavLoadingBar } from "components"
import "./Layout.scss";

class Layout extends Component {
  render() {
    const { nid, children, location, promoBannerIsVisible } = this.props;

    return (
      <div className={`LayoutWrapper${this.props.variant ? ` LayoutWrapper--${this.props.variant}` : ``}`}>
        <TopNavLoadingBar progress={10} />
        <Header location={location} />
        <SetRefIDCookie />
        <main className={`page page--id-${nid}${promoBannerIsVisible ? " promobanner-visible" : ""}`}>{children}</main>
        <Footer />
        <Modal />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const stateToProps = state => ({
  promoBannerIsVisible: state.promoBanner.promoBannerIsVisible
});

const dispatchToProps = {};

const Connected = connect(stateToProps, dispatchToProps)(
  Layout
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = Layout;
export default Connected;
