import React from "react"
import { isEmpty } from "helpers"
import "./Card.scss"

const Card = (node) => {
  const data = node
  const url = node.cta && node.cta.uri
  const cta = node.cta && node.cta.title

  return (
    <div className="Card-paragraph">
      <a href={url} className={url && `has-link`}>
        <div className="Card-paragraph_content-wrapper">
          {
            data.field_title && <div className={`Card-paragraph_header ${data.field_title_super !== null ? "with-super" : "" }`}>
              {data.field_title_super && <p className="Card-paragraph_header-super">{data.field_title_super}</p>}

              {!isEmpty(data.field_title) && (
                <h6 className="Card-paragraph_header-title">{data.field_title}</h6>
              )}
            </div>
          }

          {!isEmpty(data.body) && (
            <div className="Card-paragraph_body">
              <div dangerouslySetInnerHTML={{__html: data.body}} />
            </div>
          ) }

          {cta && (
            <div className="Card-paragraph_cta">
              <span>{cta}</span>
            </div>
          )}
        </div>
      </a>

    </div>
  )
}

export default Card
