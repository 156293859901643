import React from "react"
import PropTypes from "prop-types"

import { Container } from "components"

import HeroEllipseOrangeSVG from "../../images/FanShape.inline.svg"
import HeroEllipseBlueLargeSVG from "../../images/EllipseBlueLarge.inline.svg"

import "./Hero.scss";

const Hero = ({ children, classes, homeHero }) => {
  return (
    <div className={`Hero ${classes} ${homeHero ? "Hero-homepage" : ""}`}>
      {homeHero && (
        <div className="Hero-ellipse">
          <HeroEllipseOrangeSVG className="Hero-ellipse-o" />
          <div className="Hero-ellipse-bs" />
          <HeroEllipseBlueLargeSVG className="Hero-ellipse-bl" />
        </div>
      )}

      <Container>
        <div className="Hero-content">{children}</div>
      </Container>
    </div>
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hero
