import { combineReducers } from 'redux';

import filters from './actions/filters';
import modal from './actions/modal';
import promoBanner from './actions/promoBanner';
import search from './actions/search';

export default combineReducers({
  filters,
  modal,
  promoBanner,
  search
});
