import React from "react"
// import GoogleMapReact from "google-map-react"

// const defaultProps = {
//   center: {
//     lat: 37.7892,
//     lng: -122.3977,
//   },
//   zoom: 15,
// }

// const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMaps = (props) => (
  <div style={{ height: "560px", width: "100%" }}>
    <iframe title="Oktane20 Location" src="https://www.google.com/maps/d/u/0/embed?mid=12hZvQh0WKDxipoYfLkKsk9XYAtLn5NGg" height="560"></iframe>
    {/* <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDuuXQRM1DPkSUR6U42-Y2u_IMFUk3Z6xs" }}
      defaultCenter={defaultProps.center}
      defaultZoom={15}
    >
      <AnyReactComponent lat={props.lat} lng={props.lng} text={"Oktane20"} />
    </GoogleMapReact> */}
  </div>
)

export default GoogleMaps
