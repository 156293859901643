import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Paragraph, SectionHeading } from "components";
import "./PricingSectionParagraph.scss"

const PricingSectionParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  const numCols = data.field_pricing_columns ? data.field_pricing_columns.length : 0;

  return (
    <Container>
      <div className="Pricing-section">
        <SectionHeading data={data} classes="Pricing-section_header" />
        <div className={`Pricing-section_body columns flex-parent flex-cols-${numCols}`}>
          {(data.field_pricing_columns || []).map(
            ({ target_revision_id }, index) => {
              return (
                <Paragraph
                  key={target_revision_id}
                  type="paragraph__pricing_item"
                  id={target_revision_id}
                />
              )
            }
          )}
        </div>
      </div>
    </Container>
  )
}

export const fragment = graphql`
  fragment PricingSectionParagraph on paragraph__pricing_section {
    field_title
    field_pricing_columns {
      target_revision_id
    }
    field_cta {
      title
      uri
    }
    relationships {
      field_pricing_columns {
        drupal_internal__revision_id
        field_body {
          value
        }
        field_active
        field_price
        field_title
      }
    }
  }
`

PricingSectionParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

export default PricingSectionParagraph
