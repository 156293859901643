import React, {Component} from "react"
import PropTypes from "prop-types"
import { FilterCheckboxes, FilterDropdown, Search, DropDownIcon, CloseIcon} from "components";
import { isEmpty } from "helpers"
import "./SearchAndFilterWrapper.scss";

const filterTypeMapping = {
  checkboxes: FilterCheckboxes,
  dropdown: FilterDropdown
};

class SearchAndFilterWrapper extends Component {

  /**
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.filtersContainerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  /**
   * @description Open/close the off-canvas navigation
   *
   * @return void
   */
  handleMenuToggle = () => {
    const headerElement = window.document.querySelector('.Header');

    // Close the off canvas nav
    if (!isEmpty(this.filtersContainerRef.current) && this.filtersContainerRef.current.classList.contains('is-open')) {
      // This is the same timing as the transition for opening and closing menu
      setTimeout(() => {
        if (!isEmpty(headerElement)) {
          headerElement.style.zIndex = 999;
        }
      }, 250);

      this.filtersContainerRef.current.classList.remove('is-open');
      this.filtersContainerRef.current.classList.add('is-closed');

      // Allow the body to scroll
      this.updateElementOverflowStyle(null);

    } else if (!isEmpty(this.filtersContainerRef.current)) {
      // Open the off canvas nav
      if (!isEmpty(headerElement)) {
        headerElement.style.zIndex = 0;
      }

      this.filtersContainerRef.current.classList.remove('is-closed');
      this.filtersContainerRef.current.classList.add('is-open');

      // Prevent the body from scrolling
      this.updateElementOverflowStyle('hidden');
    }
  };

  /**
   * @description Sets the overflow CSS property for the HTML and body elements
   *
   * @return void
   */
  updateElementOverflowStyle = (overflow) => {
    const bodyElement = window.document.body;
    const HTMLElement = window.document.documentElement;

    // Prevent the body from scrolling
    if (!isEmpty(bodyElement) && !isEmpty(HTMLElement)) {
      bodyElement.style.overflow = overflow;
      HTMLElement.style.overflow = overflow;
    }
  };

  /**
   * @description Close the off-canvas when the user has filtered the categories they want.
   *
   * @see this.handleMenuToggle()
   * @return void
   */
  handleFilterToggle = () => {
    this.handleMenuToggle();
  };

  /**
   * @description Make sure the mobile and desktop navs are rendered on screen-size change.
   * @see this.clearSearchWrapper()
   * @see this.handleControlsScroll()
   *
   * @return void
   */
  handleWindowResize = () => {
    const self = this;

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(function() {
      self.updateElementOverflowStyle('auto');
    }, 250);
  };

  render() {
    const {data, parameterFilters} = this.props;

    return (
      <div className="SearchAndFilterWrapper">
        <h6>Search and filter</h6>
        <div className="SearchAndFilterWrapper-inner">
          <div className="SearchAndFilterWrapper-item SearchAndFilterWrapper-item-search">
            <div className="SearchAndFilterWrapper-item-search-inner">
              <Search title="Search" />
            </div>
            <button className="SearchAndFilterWrapper-menu-toggle-button" onClick={this.handleMenuToggle}>
              <p>Filters</p>
              <span className="dropdown-icon"><DropDownIcon /></span>
            </button>
          </div>
          <div className="SearchAndFilterWrapper-off-canvas-container is-closed" ref={this.filtersContainerRef}>
            <button className="hide-options-button" onClick={this.handleMenuToggle}>
              <p>Hide Filters</p>
              <span className="close-icon"><CloseIcon /></span>
            </button>
            {
              data.map((filter, i) => {
                if (!isEmpty(filter.options)) {
                  const Component = filterTypeMapping[filter.type];
                  return (
                    <div className={`SearchAndFilterWrapper-item SearchAndFilterWrapper-${filter.type}-${i}`} key={i}>
                      <Component
                        parameterFilters={parameterFilters}
                        data={filter} />
                    </div>
                  );
                }
                return null
              })
            }
            <button className="filter-options-button btn" onClick={this.handleFilterToggle}>
              <p>Filter</p>
            </button>
          </div>
        </div>
      </div>
      )
  }
}

SearchAndFilterWrapper.propTypes = {
  data: PropTypes.array,
  parameterFilters: PropTypes.array
}

export default SearchAndFilterWrapper
