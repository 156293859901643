import React from "react"
import PropTypes from "prop-types"
import "./Container.scss";

const Container = ({ children, variant }) => (
  <div className={`Container${variant ? ` Container--${variant}` : ``}`}>
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string
}

export default Container
