/***********
 * IMPORTS *
 ***********/
import { createReducer } from "redux-blower";

/***********
 * ACTIONS *
 ***********/
export const SET_PROMO_BANNER_VISIBILITY = "SET_PROMO_BANNER_VISIBILITY";

/***********
 * REDUCER *
 ***********/
export default createReducer({
  initialState: {
    promoBannerIsVisible: false
  },

  listenTo: {
    [SET_PROMO_BANNER_VISIBILITY]: (state, action) => ({
      ...state,
      promoBannerIsVisible: action.payload
    })
  }
});

/*******************
 * ACTION CREATORS *
 *******************/
export function setPromoBannerVisibility(visible) {
  return {
    type: SET_PROMO_BANNER_VISIBILITY,
    payload: visible
  };
}
