import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Paragraph, ShowMore } from "components"

const ReadMoreSectionParagraph = ({ node, storybookData }) => {
  const data = storybookData || node;
  const paragraphs = data.relationships.paragraphs.map(
    Paragraph
  )

  return (
    <ShowMore
      closeText={data.field_cta_text_collapse}
      openText={data.field_cta_text}
      content={paragraphs} />
  )
}

export const fragment = graphql`
  fragment ReadMoreSectionParagraph on paragraph__read_more_section {
    id
    drupal_internal__revision_id
    field_cta_text
    field_cta_text_collapse
    relationships {
      paragraphs: field_columns {
         type: __typename
         ...LogoSetSectionParagraph
         ...SpeakersParagraph
      }
    }
  }
`

ReadMoreSectionParagraph.propTypes = {
  node: PropTypes.object,
  id: PropTypes.string.isRequired,
}

export default ReadMoreSectionParagraph
