const generateAvailableTags = (items) => {
  let newTags = [];

  items.forEach((item) => {
    item.tags.forEach((tag) => {
      if (newTags.indexOf(tag) < 0) {
        newTags.push(tag);
      }
    });
  });

  return newTags;
};

module.exports = generateAvailableTags;
