import React from "react"
import Helmet from "react-helmet"
import propTypes from "prop-types"

const MetatagNormalized = ({data, skipRels, skipNames, replacements}) => {
  if (data && data.length < 1) {
    return null
  }

  return (
    <Helmet>
      {
        data.map((metatag, i) => {
          // Check if this has a "rel" attribute to skip.
          if (skipRels && skipRels.length > 0 && skipRels.includes(metatag.attributes.rel)) {
            return null
          }

          // Check if this has a "name" attribute to skip
          if (skipNames && skipNames.length > 0 && skipNames.includes(metatag.attributes.name)) {
            return null
          }

          // Title tag special handling.
          if (metatag.attributes.name === 'title') {
            return <title key={i}>{metatag.attributes.content}</title>
          }

          // Remove empty attributes.
          const attributes = metatag.attributes
          Object.keys(attributes).forEach(k => (!attributes[k] && attributes[k] !== undefined) && delete attributes[k]);

          // Go thru replacements.
          if (replacements && replacements.length > 0) {
            replacements.forEach(replacement => {
              if (attributes.href) {
                attributes.href = attributes.href.replace(replacement.from, replacement.to)
              }
              if (attributes.content) {
                attributes.content = attributes.content.replace(replacement.from, replacement.to)
              }
            })
          }

          // Print the metatag.
          const MetaTag = metatag.tag
          return <MetaTag {...attributes} key={i} />
        })
      }
    </Helmet>
  )

}

MetatagNormalized.propTypes = {
  data: propTypes.array.isRequired,
  skipRels: propTypes.array,
  skipNames: propTypes.array,
  replacements: propTypes.array
}

export default MetatagNormalized
