import React from "react";
import { graphql } from "gatsby";
import "./CardParagraph.scss"
import Card from "../Card/Card";

const CardParagraph = ({ node, id, storybookData }) => {
  const data = storybookData || node;

  return (
    <Card {...data} body={data.field_body.value} />
  )
}

export const fragment = graphql`
  fragment CardParagraph on paragraph__card {
    drupal_internal__revision_id
    cta: field_cta {
      title
      uri
    }
    field_body {
      value
    }
    field_header_tag
    field_title
    field_title_super
  }
`

export default CardParagraph
