import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Paragraph } from "components";

const BlockParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;
  const paragraphsInBlock = (data.relationships && data.relationships.field_block) ? data.relationships.field_block : [];

  if (!paragraphsInBlock.relationships) {
    return null
  }

  const paragraphs = paragraphsInBlock.relationships.paragraphs.map(
    Paragraph
  )

  return (
    <div className="BlockParagraph">{paragraphs}</div>
  )
}

BlockParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

 export const fragment = graphql`
  fragment BlockParagraph on paragraph__block {
    drupal_internal__id
    relationships {
      field_block {
        field_paragraph {
          target_revision_id
        }
        relationships {
          paragraphs: field_paragraph {
            type: __typename
            ...ColumnsParagraph
            ...PricingSectionParagraph
            ...PromoBanner
          }
        }
      }
    }
  }
`

export default BlockParagraph
