import React, { Component } from "react"
import PropTypes from "prop-types"
import "./VidyardWrapper.scss"

class VidyardWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      vidyardID: ""
    };

    this.VidyardAPI = null;
  }

  componentDidMount() {
    this.handleInit();
    this.VidyardAPI = require("@vidyard/embed-code");
  }

  cleanID = (url) => {
    const regEx = /^http.*watch\/?([a-zA-Z0-9]*)/g;
    const id = regEx.exec(url)[1]
    return id
  }

  handleInit = () => {
    const { id } = this.props;

    this.setState({
      vidyardID: id.includes("share") ? this.cleanID(id) : id
    }, this.handleRender)
  }

  handleRender = () => {
    if (this.VidyardAPI !== null) {
      this.VidyardAPI.api.renderDOMPlayers();
    }
  }

  render() {
    const { vidyardID } = this.state;
    return (
      <div className="VidyardWrapper">
        {
          vidyardID &&
          <img
              alt="Vidyard video"
              className="vidyard-player-embed"
              data-type="inline"
              data-uuid={vidyardID}
              data-v="4"
              src={`https://play.vidyard.com/${vidyardID}.jpg`}
              style={{width: "90%", margin: "auto", display: "block"}} />
        }
      </div>
    )
  }
}

VidyardWrapper.propTypes = {
  id: PropTypes.string
}

export default VidyardWrapper
