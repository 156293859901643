import React, { Component } from "react"
import PropTypes from "prop-types"
import { DropDownIcon } from "components"
import { isEmpty, wrapCTA, CustomLink } from "helpers"

//const dropdownIcon = require("../../images/dropdown-icon.svg");

class NavItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newLink: ""
    };

    this.handleLinkSetup = this.handleLinkSetup.bind(this);
  }

  componentDidMount() {
    this.handleLinkSetup();
  }

  handleLinkSetup() {
    const { href, fragment } = this.props;

    if (!isEmpty(href)) {
      const cleanedLink = href.replace("security-page/", "");

      if (!isEmpty(fragment)) {
        this.setState({
          newLink: `${cleanedLink}#${fragment}`
        });

        return
      }

      this.setState({
        newLink: cleanedLink
      });
    } else {
      this.setState({
        newLink: href
      });
    }
  }

  render() {
    const {
      classes,
      cta,
      fragment,
      hasSubmenu,
      isExternal,
      setNavVisibility,
      text,
      href
    } = this.props;

    if (cta) {
      return wrapCTA(href, text, "nav-item nav-cta btn")
    }

    if (isEmpty(href)) {
      return (
        <div className={`nav-item ${classes ? classes : ""}`}><span>{text}</span> {hasSubmenu && <span className="dropdown-icon">
          <DropDownIcon />
        </span>}</div>
      )
    }

    if (isExternal) {
      return (
        <a href={href} className={`nav-item ${classes ? classes : ""}`} rel="noopener">{text}</a>
      )
    }

    if (!isEmpty(fragment)) {
      let anchorURL = this.state.newLink;
      return (
        <CustomLink
          to={anchorURL}
          onClick={setNavVisibility}
          className={`nav-item ${classes ? classes : ""}`}
        >
          {text}{" "}
          {hasSubmenu && (
            <span className="dropdown-icon">
              <DropDownIcon />
            </span>
          )}
        </CustomLink>
      )
    }

    return (
      <CustomLink
        to={this.state.newLink}
        onClick={setNavVisibility}
        className={`nav-item ${classes ? classes : ""}`}
      >
        {text}{" "}
        {hasSubmenu && (
          <span className="dropdown-icon">
            <DropDownIcon />
          </span>
        )}
      </CustomLink>
    )
  }
}

NavItem.propTypes = {
  cta: PropTypes.bool,
  text: PropTypes.string,
  href: PropTypes.string,
}

export default NavItem
