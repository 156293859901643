import React from "react"
import { graphql } from "gatsby"
import { GoogleMaps }  from 'components'
import "./MapParagraph.scss"

const MapParagraph = ({ id, node, storybookData }) => {

    return (
        <div className="GoogleMaps">
            <div className="GoogleMaps-map">
                <GoogleMaps {...node.field_geolocation}></GoogleMaps>
            </div>
            <div className="GoogleMaps-content">
                <h5 className="GoogleMaps-content_title">Oktane20 will be held at<br /> Moscone West in San Francisco, California.</h5>
            </div>
        </div>
    )
}

export const fragment = graphql`
  fragment MapParagraph on paragraph__map {
    id
    field_geolocation {
        lat
        lng
    }
  }
`

export default MapParagraph
