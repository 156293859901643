const colorConverter = elem => {

    const sectionStyles = {
        '#FFFFFF': 'white',
        '#000000': 'black',
        '#292654': 'blue-extra-dark',
        '#13499E': 'blue-dark',
        '#0090D4': 'blue',
        '#68B9D9': 'blue-light',
        '#95446B': 'plum',
        '#DE4E69': 'pink-dark',
        '#F16F84': 'pink-light',
        '#F16E2B': 'orange-extra-dark',
        '#F08751': 'orange-dark',
        '#F89E1C': 'orange',
        '#FCBD4F': 'yellow',
        '#00847D': 'green-dark',
        '#36B68C': 'green',
        '#ABD271': 'green-light',
    }
    if (sectionStyles[elem]) {
        return sectionStyles[elem]
    }
  return 'transparent'
}

module.exports = colorConverter
