import React, { Component } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "helpers"
import { AgendaSpeaker, PlusIcon, CopyIcon } from "components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import "./AgendaItem.scss";

class AgendaItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      targetNode: null,
      value: "https://www.oktane20.com",
      copied: false,
    }

    this.divToFocus = React.createRef();
  }

  componentDidMount() {
    this.setUpValues();
  }

  /**
   * @description Setup or initial values.
   * This will get the height between the AgendaAndFilters component and top of the screen.
   *
   * @return void
   */
  setUpValues = () => {
    const agendaAndFilters = window.document.querySelector('.AgendaAndFilters');

    if (!isEmpty(agendaAndFilters)) {
      let offset;

      // WARN this could break if the DOM changes, so the else is a fallback.
      if (!isEmpty(agendaAndFilters.offsetParent.offsetTop) &&
        !isEmpty(agendaAndFilters.offsetParent.offsetParent.offsetTop) &&
        !isEmpty(agendaAndFilters.offsetParent.offsetParent.offsetParent.offsetTop)
      ) {
        // A little sanity check.
        if (agendaAndFilters.offsetParent.offsetParent.classList.contains('SectionLayout')) {
          offset = agendaAndFilters.offsetParent.offsetTop + agendaAndFilters.offsetParent.offsetParent.offsetTop + agendaAndFilters.offsetParent.offsetParent.offsetParent.offsetTop;
        }
      }

      if (isEmpty(offset)) {
        offset = agendaAndFilters.getBoundingClientRect().top;
      }

      this.agendaAndFiltersoffsetTop = offset;
    }
  };

  /**
   * @description Scroll current node to the top of the screen.
   *
   * @param {object} targetNode
   * @return void
   */
  scrollToNavBar = (targetNode) => {
    const ua = window.navigator.userAgent;
    const isIE = /MSIE|Trident/.test(ua);
    const isSafari = /safari/mi.test(ua);
    const isEdge = /Edg/mi.test(ua);

    if (!isEmpty(targetNode) && targetNode.firstChild.classList.contains('AgendaItem-content')) {
      const headerElement = window.document.querySelector('.Header');
      const headerElementHeight = window.getComputedStyle(headerElement).getPropertyValue('height');
      const marginTop = window.getComputedStyle(targetNode.firstChild).getPropertyValue('margin-top');

      const marginTopTmp = marginTop.replace(/px/, '');
      const headerElementHeightTmp = headerElementHeight.replace(/px/, '');

      const distance = this.agendaAndFiltersoffsetTop + targetNode.offsetTop - marginTopTmp - headerElementHeightTmp - 30;

      if (!isIE || !isSafari || !isEdge) {
        window.scrollTo({
          top: distance,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo(0, distance);
      }
    }
  };


  /**
   * @description Close all of the open AgendaItems, scroll to the top and open the current item.
   *
   * @param {object} content
   * @param {object} targetNode
   * @return void
   */
  closeAllAndOpenItemsAndScroll = (content, targetNode) => {
    const agendaItems = window.document.querySelectorAll('.AgendaItem-body.is-open');
    const agendaItemsArray = Array.from(agendaItems);
    const agendaItemsArrayLength = agendaItemsArray.length;


    for (let item = 0; item < agendaItemsArrayLength; item++) {
        if (agendaItemsArray[item]) {
          // Close all items
          agendaItemsArray[item].style.maxHeight = '0';
          agendaItemsArray[item].classList.remove('is-open');
        }
    }

    // Scroll to target
    this.scrollToNavBar(targetNode);

    // Open the current target.
    content.classList.add('.is-open');
    content.style.maxHeight = `${content.scrollHeight}px`;

  };

  handleOpen = () => {
    const { targetNode } = this.state;

    let content = undefined;
    let closedContent = undefined;

    if (!isEmpty(targetNode)) {
      content = targetNode.querySelector(".AgendaItem-body.is-open");
      closedContent = targetNode.querySelector(".AgendaItem-body.is-closed");
    }

    if (!isEmpty(content)) {

      this.closeAllAndOpenItemsAndScroll(content, targetNode);

    } else if (!isEmpty(closedContent)) {
      closedContent.style.maxHeight = "0px";
    }
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleClick = e => {
    e.preventDefault();
    let targetElement = null

    if ((this.props.data.body || this.props.data.room) && e.target.classList.contains("AgendaItem-content")) {
      targetElement = e.target.parentNode;
      this.setState({
        isOpen: !this.state.isOpen,
        targetNode: targetElement
      }, this.handleOpen);
    }
    return
  }

  onCopy = () => {
    this.setState({ copied: true })
    setTimeout(() => this.setState({ copied: false }), 2500 )
  }

  render() {
    const { data } = this.props
    const { isOpen } = this.state
    const path = window && window.location.href;
    const cleanPath = path.replace(/(#)[0-9]+/, "");

    return (
      <div className="AgendaItem" id={data.nid} ref={this.divToFocus}>
        <div className={`AgendaItem-wrapper ${isOpen ? "is-open" : ""}`}>
          <div className="AgendaItem-content" onClick={this.handleClick} aria-label="Open event description" role="button" tabIndex="0" onKeyDown={this.handleKeyPress}>
            <div className="AgendaItem-header">
              <div className="AgendaItem-header-main">
                <h6 className="AgendaItem-header-main-title">{data.title}</h6>
                <div className="AgendaItem-header-main-time">
                  {data.start_time} to {data.end_time}
                </div>
              </div>
              <div className="AgendaItem-header-icons">
                {
                  (data.body || data.room) && <div className="AgendaItem-header-expand">
                    <PlusIcon isOpen={isOpen} />
                  </div>
                }
                <div className="AgendaItem-header-share">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={`${cleanPath}#${data.nid}`}>
                    <div>
                      <span>
                        <CopyIcon isCopied={this.state.copied} />
                      </span>
                      <span className={`AgendaItem-copylabel ${this.state.copied ? 'active' : ''}`}>
                        {this.state.copied ? (
                          'Link copied'
                        ) : null}
                      </span>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <div className="AgendaItem-info">
              {
                data.categories && data.categories.topic && data.categories.topic.length > 0 && <div className="AgendaItem-info-tags">
                  {
                    data.categories.topic.map((item, i) => {
                      return (
                        <span className="AgendaItem-info-tag-topic" key={i}>{item}</span>
                      )
                    })
                  }
                </div>
              }

              {
                data.speakers.length > 0 && <div className="AgendaItem-info-speakers">
                <p className="AgendaItem-info-speakers-title">Speakers:</p>
                  <div className="AgendaItem-info-speakers-wrapper">
                    {
                      data.speakers.map((speaker, i) => {
                        return (
                          <AgendaSpeaker data={speaker} key={i} />
                        )
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </div>

          <div className={`AgendaItem-body ${isOpen ? "is-open" : "is-closed"}`}>
            {data.room && <p className="AgendaItem-body-room">Location: <strong>{data.room}</strong></p> }
            <div className="AgendaItem-body-content" dangerouslySetInnerHTML={{__html: data.body}} />
          </div>
        </div>
      </div>
    )
  }
}

AgendaItem.propTypes = {
  data: PropTypes.object
}

export default AgendaItem
