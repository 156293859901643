import React, { Component } from "react"
import "./Form.scss";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successfulSubmit: false,
      formIsLoaded: false
    };

    const { formId } = this.props;
    this.formElement = `mktoForm_${formId}`;
  }

  componentDidMount() {
    this.checkMarketoScriptLoad();
  }

  checkMarketoScriptLoad() {
    if (!this.state.formIsLoaded) {
      this.handleMarketoLoading();
      this.changeEmailOptInCanada();
    }
  }

  changeEmailOptInCanada() {
    const self = this;

    if (typeof window.MktoForms2 !== 'undefined') {

      window.MktoForms2.whenReady(function(form) {
        const placeholder = document.querySelector(`#${self.formElement} .mktoPlaceholderEmail_Opt_In__c`);
        const placeholderParent = placeholder.parentElement;

        placeholderParent.classList.add('Email-op-in');
      });

    }
  }

  handleMarketoLoading() {
    if (typeof window.MktoForms2 !== 'undefined') {
      // eslint-disable-next-line no-undef
      MktoForms2.loadForm("//pages.okta.com", "380-NLU-416", this.props.formId);
      this.setState({
        formIsLoaded: true
      })
    } else {
      window.setTimeout(() => {
        this.checkMarketoScriptLoad();
      }, 500)
    }
  }

  render() {
    const { formId } = this.props;

    return (
      <div className="Form">
        <div className="Form-wrapper">
          <form ref={this.formElement} id={`mktoForm_${formId}`} />
        </div>
      </div>
    )
  }
}

export default Form
