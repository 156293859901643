import React from "react";
import PropTypes from "prop-types";
import BlogAuthorByline from "./BlogAuthorByline/BlogAuthorByline";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import "./BlogAuthor.scss";

const DEFAULT_AUTHOR = "Okta";

const BlogAuthor = ({ viewMode, authors }) => {
  let author;
  let avatar;
  if (authors) {
    if (authors.length > 1) {
      avatar = "";
      author = authors.map((i) => i.name).join(" and ");
    } else if (authors.length === 1) {
      avatar = getImage(authors[0].image);
      author = authors[0].name;
    }
  } else if (!authors) {
    author = DEFAULT_AUTHOR;
  }


  switch (viewMode) {
    case "byline-small":
      return (
        <BlogAuthorByline image={avatar} name={author} viewMode={`small`} />
      );
      break;

    case "byline-large":
      return (
        <BlogAuthorByline image={avatar} name={author} viewMode={`large`} />
      );
      break;

    default:
      return (
        <div className={`BlogAuthors`}>
          {Array.isArray(authors) ? (
            authors.map((author, i) => {
              const avatar = getImage(author.image);
              return (
                <div className={`BlogAuthor`} key={i}>
                  <div className={`BlogAuthor__header`}>
                    <div className={`BlogAuthor__headshot`}>
                      {author.image && (
                        <GatsbyImage
                          image={avatar}
                          className={`BlogAuthor__image`}
                          alt=""
                        />
                      )}
                    </div>
                    <div className={`BlogAuthor__text`}>
                      <div className={`BlogAuthor__text-name`}>
                        {author.name}
                      </div>
                      <div className={`BlogAuthor__text-title`}>
                        {author.jobTitle}
                      </div>
                    </div>
                  </div>
                  {author.bio.bio && (
                    <div
                      className={`BlogAuthor__bio`}
                      dangerouslySetInnerHTML={{ __html: author.bio.bio }}
                    />
                  )}
                </div>
              );
            })
          ) : (
            <div className={`BlogAuthor`}>
              <div className={`BlogAuthor__header`}>
                <div className={`BlogAuthor__headshot`}>
                  <GatsbyImage
                    image=""
                    className={`BlogAuthor__image`}
                    alt=""
                  />
                </div>
                <div className={`BlogAuthor__text`}>
                  <div className={`BlogAuthor__text-name`}>
                    {DEFAULT_AUTHOR}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
      break;
  }
};

export default BlogAuthor;

BlogAuthor.propTypes = {
  authors: PropTypes.array,
};
