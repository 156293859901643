import React, { Component } from "react"
import Cookies from 'js-cookie'
import "./Registration.scss";

const logoImage = require("../../images/Oktane20_logo-purple.png");

const okta_sso = "https://login.okta.com/?SAMLRequest=fc8%2FC8IwEAXwXfA7hOy22kmONqWLIOjivz3EYItNUnOX1o9vpAp1cTx47%2Fe4vHyalvXaY%2BNswVfJkjNtlbs29lbw82mzWPNSzGc5StNmHVSBanvQj6CR2OVby961CFmEMVfw4C04iQ2ClUYjkIJjtd9BjELnHTnlWh5hxkYatohB%2B6nyH5GI2lOc56Im6iBNh2FI3J1kopxJVa8t5enUFp%2Fz9wvxAg%3D%3D&RelayState=http://www.cvent.com/d/46qxcx/4W";

const okta_cvent = "http://www.cvent.com/d/46qxcx/4W";

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cventLink: okta_cvent,
      refState: "",
      ssoLink: okta_sso
    };
  }

  componentDidMount() {
    this.getRefID();
  }

  getRefID = () => {
    const refCookie = this.checkCookie();
    if (refCookie) {
      this.updateUrls(refCookie);
    } else if (this.props.refLink !== "" && this.props.refLink !== undefined) {
      const refId = this.props.refLink;
      this.setState({
        refState: refId ? refId : ""
      }, this.setCookie)
    }

    window.history.replaceState({}, "", window.location.pathname);
  }

  checkCookie = () => {
    const refCookie = Cookies.get('_okta_cvent');

    if (refCookie) {
      return refCookie
    }
  }

  setCookie = () => {
    const { refState } = this.state;

    if (refState && refState !== "") {
      Cookies.set('_okta_cvent', refState);

      let url = window.location.href;
      let start = url.indexOf('RefID');
      let end = url.indexOf('&', start);
      let newUrl = url.substr(0, start);

      if (end >= 0) {
        newUrl += url.substr(end+1);
      }

      if (newUrl.slice(-1) === '?' || newUrl.slice(-1) === '&') {
        newUrl = newUrl.substr(0, newUrl.length - 1);
      }

      if (typeof window.history.pushState === 'function') {
        window.history.replaceState({}, '', newUrl);
      }

      this.updateUrls();
    }
  }

  updateUrls = (refCookie) => {
    const { refState } = this.state;

    const updateURL = (url, ref) => {
      if (url.indexOf('?') >= 0 && ref !== "") {
        if (url.indexOf('RelayState') >= 0) {
          url += '%3FRefID%3D'+ref; // SSO link
        } else {
          url += '&RefID=' + ref;
        }
      } else if (ref !== "") {
        url += '?RefID=' + ref;
      }

      return url;
    }

    if (refCookie) {
      this.setState({
        ssoLink: updateURL(okta_sso, refCookie),
        cventLink: updateURL(okta_cvent, refCookie)
      })
    } else {
      this.setState({
        ssoLink: updateURL(okta_sso, refState),
        cventLink: updateURL(okta_cvent, refState)
      })
    }
  }

  render() {
    const { ssoLink, cventLink } = this.state;
    return (
      <div className="Registration">
        <h2 className="Registration-logo">
          <img src={logoImage} alt="Oktane20" />
        </h2>

        <div className="Registration-content-wrapper">
          <div className="Registration-content-choice">
            <p>Already have an Okta account?</p>
            <a className="btn" id="oktane_sso_link" href={ssoLink}>Continue with Okta</a>
          </div>
          <div className="Registration-content-divider"></div>
          <div className="Registration-content-choice">
            <p>Don't have an Okta account, or need to register on behalf of someone? <a id="oktane_cvent_link" className="Registration-link" href={cventLink}>Click here to register</a></p>
          </div>
        </div>

        <small>Registration powered by CVENT</small>
      </div>
    )
  }
}

Registration.propTypes = {
}

export default Registration
