import "./src/styles/global.scss"
import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider


// export const onRouteUpdate = ({ location, prevLocation }) => {
//     console.log('new pathname', location.pathname)
//     console.log('old pathname', prevLocation ? prevLocation.pathname : null)
// }

// import "./src/styles/global.scss"

// import React from "react"
// import AuthWrapper from "./src/components/Okta/auth-wrapper"

// export const wrapRootElement = ({ element }) => (
//   <AuthWrapper>{element}</AuthWrapper>
// )
