import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AgendaDay } from "components"
import { setFilteredItems, setFilteredTags } from "state/actions/filters";
import { isEmpty, slugify, generateAvailableTags, getFoundItems, search } from "helpers"
import "./AgendaItems.scss";

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data || prevProps.activeFilters !== this.props.activeFilters || prevProps.searchTerms !== this.props.searchTerms) {
      this.handleGenerateTagList();
    }
  }

  handleSortByDate = (data) => {
    let days = {};

    data.forEach((item, i) => {
      if (!days.hasOwnProperty(item.day)) {
        days[item.day] = [item];
      } else {
        days[item.day].push(item);
      }
    });
    this.handleUpdateItems(days);
  }

  handleUpdateItems = (data) => {
    const keys = Object.keys(data);
    let updatedItems = [];

    keys.forEach((key) => {
      let tempObject = {
        day: key,
        items: data[key]
      }

      updatedItems.push(tempObject);
    })

    this.setState({
      items: updatedItems
    });
  }

  handleFilterItems = (data) => {
    const { activeFilters, searchTerms } = this.props;
    const foundItems = getFoundItems(data, activeFilters, isEmpty);
    const filteredBySearch = search(foundItems, searchTerms);

    const newTags = generateAvailableTags(filteredBySearch);
    this.props.setFilteredTags(newTags);

    this.props.setFilteredItems(filteredBySearch);
    this.handleSortByDate(filteredBySearch);
  }

  handleGenerateTagList = ()=> {
    const { data } = this.props;

    data.forEach((item) => {
      let keys = Object.keys(item.categories);
      let allCategories = [];
      let valuesArray = [];

      keys.forEach((key) => {
        valuesArray.push(item.categories[key])
      })

      valuesArray.forEach((arr) => {
        allCategories = allCategories.concat(arr);
      });

      let lcAllCategories = allCategories.map((tag) => {
        return slugify(tag);
      });

      item.tags = lcAllCategories;
    });

    this.handleFilterItems(data);
  }

  render() {
    const { filteredItems, itemsAreDownloaded, noMatchMessage } = this.props;
    const { items } = this.state;

    return (
      <div className="AgendaItems">
        {
          !isEmpty(items) && items.map((item, i) => {
            return (
              <AgendaDay key={i}  data={item} />
            );
          })
        }

        {
          itemsAreDownloaded && filteredItems.length < 1 && <h3 className="AgendaItems_no-match">{noMatchMessage}</h3>
        }
      </div>
    );
  }
}

Items.propTypes = {
  activeFilters: PropTypes.array,
  filteredItems: PropTypes.array,
  filteredTags: PropTypes.array,
  noMatchMessage: PropTypes.string,
  searchTerms: PropTypes.string,
}

const stateToProps = state => ({
  activeFilters: state.filters.activeFilters,
  filteredItems: state.filters.filteredItems,
  filteredTags: state.filters.filteredTags,
  itemsAreDownloaded: state.filters.itemsAreDownloaded,
  searchTerms: state.search.searchTerms
});

const dispatchToProps = {
  setFilteredItems,
  setFilteredTags
};

const Connected = connect(stateToProps, dispatchToProps)(
  Items
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = Items;
export default Connected;
