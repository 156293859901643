import React from "react"
import { SectionLayout, Container } from "components"
import "./FooterInformation.scss";

const FooterInformation = () => (
  <SectionLayout classes="sl-background-color-blue-light">
  <Container>
    <div className="FooterInformation" id="information">
      <div className="FooterInformation-item">
        <h3>Hosted by Okta</h3>
        <p>Okta is the leading independent provider of identity for the enterprise. The Okta Identity Cloud enables organizations to securely connect the right people to the right technologies at the right time. With over 6,000 pre-built integrations to applications and infrastructure providers, Okta customers can easily and securely use the best technologies for their business. </p>
      </div>

      <div className="FooterInformation-item">
        <h3>OktaneConf</h3>
        <div id="twitter-moment-container"></div>
        <iframe id="twitter-widget-0" scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen className="twitter-timeline twitter-timeline-rendered" data-widget-id="profile:OktaneConf" title="Twitter Timeline" />
      </div>
    </div>
  </Container>
  </SectionLayout>
)

export default FooterInformation
