import React from "react"

const TwitterIcon = () => (
  <svg className="TwitterIcon" width="17px" height="14px" viewBox="0 0 17 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#FFFFFF">
        <path d="M16.2039867,2.0683224 C15.6367931,2.3278107 15.0261087,2.5032919 14.3855242,2.5816985 C15.0396996,2.1784649 15.5416568,1.5390783 15.7781385,0.7774148 C15.1656419,1.1507792 14.4888151,1.4224019 13.7657792,1.568014 C13.189525,0.9342279 12.3659164,0.5384615 11.45442,0.5384615 C9.7066302,0.5384615 8.2886462,1.9992499 8.2886462,3.8007334 C8.2886462,4.056488 8.315828,4.3047754 8.3701916,4.5437286 C5.7380872,4.4074506 3.4049826,3.1100092 1.8420291,1.1339778 C1.569305,1.6174848 1.4134627,2.1784649 1.4134627,2.775848 C1.4134627,3.9071423 1.9725017,4.9058922 2.822386,5.4911409 C2.3041197,5.4752729 1.8148473,5.3268606 1.3871869,5.0841737 L1.3871869,5.1243104 C1.3871869,6.7055088 2.4789893,8.0244187 3.9286853,8.3231103 C3.6632097,8.39965 3.3832372,8.4379198 3.094204,8.4379198 C2.8903405,8.4379198 2.6910073,8.4183182 2.4980165,8.3800483 C2.9012132,9.675623 4.0700307,10.6193016 5.4563026,10.6445037 C4.3726547,11.5200433 3.0063162,12.0418202 1.5230959,12.0418202 C1.267587,12.0418202 1.0147963,12.0268856 0.7674419,11.9970164 C2.1691167,12.9210934 3.8344551,13.4615385 5.6221115,13.4615385 C11.4480776,13.4615385 14.6328786,8.4911243 14.6328786,4.1796983 C14.6328786,4.0378198 14.6301604,3.8959413 14.6247241,3.7568631 C15.2435631,3.2966914 15.7808567,2.7226436 16.2039867,2.0683224" />
      </g>
    </g>
  </svg>
)

TwitterIcon.propTypes = {
}

export default TwitterIcon

