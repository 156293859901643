import React, { Component } from "react"

import LoadingBar from "react-top-loading-bar"

export default class TopNavLoadingBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      progress: props.progress ? props.progress : 10,
    }
  }

  componentDidMount() {
    this.setState({progress: 100})
  }

  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color="#3F59E4"
          progress={this.state.progress}
          // onRef={ref => (this.LoadingBar = ref)}
        />
      </div>
    )
  }
}
