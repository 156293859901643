import React from "react"

// To avoid ID collisions if multiple SVGs of this type are on a single page
const generateMaskID = () => {
  const hash = Math.floor(Math.random() * 10000);
  return `CircleScanlineMask-${hash}`
}

const CircleScanline = () => {
  const randomID = generateMaskID();
  return (
    <svg className="CircleScanline" width="768" height="768" viewBox="0 0 768 768" fill="none">
      <mask id={randomID} masktype="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="768" height="768">
        <circle cx="383.926" cy="384.371" r="383.5" fill="#DE4E69" />
      </mask>
      <g mask={`url(#${randomID})`}>
        <path d="M-101.14 471.762H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 460.906H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 450.048H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 439.191H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 428.334H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 417.478H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 406.625H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 395.769H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 384.911H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 374.054H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 363.197H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 352.34H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 341.483H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 330.631H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 319.774H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 308.917H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 298.06H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 287.203H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 276.346H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 265.49H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 254.637H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 243.78H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 232.923H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 222.066H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 211.209H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 200.352H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 189.5H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 178.643H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 167.786H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 156.929H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 146.072H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 135.215H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 124.358H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 113.506H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 102.648H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 91.7917H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 80.9351H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 70.0779H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 59.2207H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 48.364H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 37.5115H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 26.6548H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 15.7974H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 4.94043H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 765.273H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 754.42H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 743.563H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 732.706H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 721.85H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 710.993H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 700.136H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 689.283H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 678.426H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 667.569H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 656.712H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 645.855H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 634.998H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 624.142H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 613.289H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 602.432H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 591.575H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 580.718H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 569.861H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 559.004H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 548.147H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 537.295H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 526.438H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 515.581H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 504.724H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 493.867H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
        <path d="M-101.14 483.01H864.3" stroke="#F89E1C" strokeWidth="6" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}

CircleScanline.propTypes = {
}

export default CircleScanline
