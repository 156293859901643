const getFoundItems = (data, activeFilters, isEmpty) => {
  let foundItems = [];
  let currentFilters = [];

  const checkData = () => {
    data.forEach((item) => {

      if (currentFilters.length > 0) {
        let itemContains = [];
        // loop through all currentFilters and if item has all then return it
        currentFilters.forEach((filterItem) => {
          if (item.tags.indexOf(filterItem) >= 0) {
            itemContains = itemContains.concat(true);
          } else {
            itemContains = itemContains.concat(false);
          }
        })
        if (itemContains.indexOf(false) < 0) {
          foundItems = foundItems.concat(item);
        }
      } else {
        foundItems = foundItems.concat(item);
      }
    })
  };

  activeFilters.forEach((item) => {
    currentFilters = currentFilters.concat(item)
  });

  const checkActiveFilters = () => {
    if (currentFilters.length > 0) {
      checkData();
    } else {
      foundItems = data;
    }
  };

  checkActiveFilters();

  return foundItems;
};

module.exports = getFoundItems;
