import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FilterDropdownItem } from "components";
import { thereAreNoActiveFilters } from "helpers"
import { setActiveFilters, updateFilterObjects } from "state/actions/filters";
import "./FilterDropdown.scss";

class FilterDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      title: null
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSetTitle = this.handleSetTitle.bind(this);
    this.handleSelectReset = this.handleSelectReset.bind(this);
    this.handleGenerateFilterObjects = this.handleGenerateFilterObjects.bind(this);
    this.initEmptyFilterObject = this.initEmptyFilterObject.bind(this);
  }

  componentDidMount() {
    this.handleSetTitle();
    this.initEmptyFilterObject();
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.activeFilters !== this.props.activeFilters) {
      if (thereAreNoActiveFilters(this.props.activeFilters)) {
        this.handleSetTitle();
      }
    }
  }

  handleSelect(e, value, parentname, text) {
    const newValue = value || e.target.dataset.value.toLowerCase();
    const parentName = parentname || e.target.dataset.parentname.toLowerCase();
    const newText = text || e.target.innerText;

    this.setState({
      title: newText
    });

    this.handleGenerateFilterObjects(newValue, parentName);
  }

  handleSelectReset(e) {
    this.handleGenerateFilterObjects([], e.target.dataset.parentname);
    this.handleSetTitle();
  }

  handleSetTitle() {
    this.setState({
      title: this.props.title
    });
  }

  initEmptyFilterObject() {
    const { activeFilters, fullFilterObject, parameterFilters } = this.props;

    const getTextName = (value) => {
      let result = null;
      fullFilterObject.options.forEach((option) => {
        if (option.value === value) {
          result = option.label;
        }
      });

      return result;
    };

    const setObject = (name, options) => {
      const thisObj = {
        name: name,
        options: options
      };

      if (activeFilters.indexOf(thisObj) < 0) {
        activeFilters.push(thisObj);
      }
    };

    const doesParameterBelongToThisDropdown = () => {
      let result = false;

      parameterFilters.forEach((filter) => {
        const name = Object.keys(filter)[0];
        const value = filter[name];

        if (name === fullFilterObject.name) {
          const title = getTextName(value);
          if (title !== false) {
            setObject(name, [value]);
            this.setState({
              title: title
            });
            result = true;
          }
        }
      });

      return result
    };

    if (parameterFilters.length > 0) {
      const result = doesParameterBelongToThisDropdown();
      if (!result) {
        setObject(fullFilterObject.name, []);
      }
    } else {
      setObject(fullFilterObject.name, []);
    }
  }

  handleGenerateFilterObjects(newValue, parentName) {
    const { activeFilters } = this.props;
    activeFilters.forEach((item) => {
      if (item.name === parentName) {
        const itemIndex = activeFilters.indexOf(item);

        let newObject = Object.assign({}, item, {
            name: parentName,
            options: [newValue]
        });

        const newFilters = [...activeFilters];
        newFilters.splice(itemIndex, 1, newObject);
        this.props.setActiveFilters(newFilters);
      }
    });
  }

  render() {
    const { data, filteredTags, fullFilterObject } = this.props;
    const { title } = this.state;

    return (
      <div className="Filter_Dropdown">
        <div className={`Filter_Dropdown-header ${title !== this.props.title ? "is-active" : ""}`}>
          <span className="Filter_Dropdown-title">{title}</span>
          <span className="Filter_Dropdown-arrow" />
        </div>
        <div className="Filter_Dropdown-body">
          <div role="button" tabIndex={0} lassName="Filter_Dropdown-option" data-value="" data-parentname={fullFilterObject.name} onKeyDown={this.handleSelectReset} onClick={this.handleSelectReset}>
            All
          </div>
          {
            data.map((item, i) => {
              return (
                <FilterDropdownItem
                  key={i}
                  setFilter={this.handleSelect}
                  item={item}
                  itemParentName={fullFilterObject.name}
                  filteredTags={filteredTags} />
              )
            })
          }
        </div>
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  activeFilters: PropTypes.array,
  filteredItems: PropTypes.array,
  filteredTags: PropTypes.array,
  item: PropTypes.object
}

const stateToProps = state => ({
  activeFilters: state.filters.activeFilters,
  filteredItems: state.filters.filteredItems,
  filteredTags: state.filters.filteredTags,
  itemsAreDownloaded: state.filters.itemsAreDownloaded
});

const dispatchToProps = {
  setActiveFilters,
  updateFilterObjects
};

const Connected = connect(stateToProps, dispatchToProps)(
  FilterDropdown
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = FilterDropdown;
export default Connected;
