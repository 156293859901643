import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlogCard from "../../../templates/blog/BlogCard/BlogCard";

const LatestArticlesParagraph = (props) => {
  const data = useStaticQuery(graphql`
    {
      allNodeSecurityBlogEntry(sort: {fields: created, order: DESC}) {
        nodes {
          ...BlogCard
        }
      }
    }
  `)

  let nodes = data.allNodeSecurityBlogEntry.nodes.filter(i => !i.title.startsWith('WEBDEV EXCLUDE'))

  // If it's not staging, only show non-hidden blog posts.
  if (process.env.GATSBY_IS_STAGING !== '1') {
    nodes = nodes.filter(i => i.field_hidden !== true)
  }

  const blogCards = nodes.slice(0, 3).map(i => <BlogCard {...i} key={i.id} />)

  return (
    <div className="columns flex-parent flex-cols-3 flex-direction-left flex-content-align-top">
      {blogCards}
    </div>
  )
}

export const fragment = graphql`
  fragment LatestArticlesParagraph on paragraph__security_latest_articles {
    id
  }
`

export default LatestArticlesParagraph
