import React from "react";
import { graphql } from "gatsby";
import { Hero } from "components"
import { wrapCTA } from "helpers"
import "./HeroH1HtmlCtaParagraph.scss"

const HeroH1HtmlCtaParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  return (
    <Hero classes="HeroH1HtmlCtaParagraph">
      {data.field_header_multiline && <h1 className="HeroH1HtmlCtaParagraph-title">{data.field_header_multiline.value}</h1>}
      {data.field_body && <div className="HeroH1HtmlCtaParagraph-body" dangerouslySetInnerHTML={{__html: data.field_body.value}} />}
      {data.field_cta && wrapCTA(data.field_cta.uri, data.field_cta.title, "HeroH1HtmlCtaParagraph-cta btn")}
    </Hero>
  )
}

export const fragment = graphql`
 fragment HeroH1HtmlCtaParagraph on paragraph__hero_h1_html_cta {
   field_body {
     value
   }
   field_cta {
     title
     uri
   }
   field_header_multiline {
     value
   }
   drupal_internal__revision_id
 }
`

export default HeroH1HtmlCtaParagraph
