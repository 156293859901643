import React from "react";

import "./BlogPagination.scss";

const BlogPagination = (data) => {
  // TODO: remove these comments
  //   const path =
  //     data.path.endsWith(`/${data.currentPage}`) ?
  //       data.path.substring(0, data.path.length - data.currentPage.toString().length - 1) :
  //       data.path;

  const previousPath = `/articles/${
    data.currentPage > 2 ? data.currentPage - 1 : ``
  }`;
  const nextPath = `/articles/${data.currentPage + 1}`;

  return (
    <div className={`BlogPagination`}>
      <div className={`BlogPagination__container`}>
        {data.currentPage > 1 && (
          <div className={`BlogPagination__previous`}>
            <a href={previousPath}>← Previous</a>
          </div>
        )}
        <div className={`BlogPagination__summary`}>
          Page {data.currentPage} of {data.numBlogPages}
        </div>
        {data.currentPage < data.numBlogPages && (
          <div className={`BlogPagination__next`}>
            <a href={nextPath}>Next →</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPagination;
