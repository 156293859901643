import React from "react"
import { graphql } from "gatsby"
import "./ImageContentParagraph.scss"

const ImageContentParagraph = (props) => {
  const block = 'ImageContentParagraph'
  const blockClasses = [
    block,
    `${block}--${props.field_columns_align_items}`,
  ]
  const containerClasses = [
    `${block}__container`,
    `flex-direction-${props.field_flex_direction}`
  ]

  const imageFlex = props.field_image_width && {
    flex: `0 0 ${props.field_image_width}`
  }
  const image = props.relationships.field_media_image && (
    <div className={`${block}__image`} style={imageFlex}>
      <img
        alt={props.relationships.field_media_image.field_media_image.alt}
        src={
          props.relationships.field_media_image.relationships.field_media_image
            .localFile.childImageSharp.fluid.src
        }
      />
      {props.field_image_caption && <div className={`${block}__caption`}>{props.field_image_caption}</div>}
    </div>
  )

  const body = props.field_body && <div className={`${block}__body`} dangerouslySetInnerHTML={{__html: props.field_body.value}} />

  return (
    <div className={blockClasses.join(' ')}>
      <div className={containerClasses.join(' ')}>
        {image}
        {body}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment ImageContentParagraph on paragraph__image_content {
    drupal_internal__revision_id
    field_columns_align_items
    field_image_width
    field_flex_direction
    field_image_caption
    field_body {
      value
    }
    relationships {
      field_media_image {
        field_media_image {
          alt
        }
        relationships {
          field_media_image {
            localFile {
              url
              id
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ImageContentParagraph
