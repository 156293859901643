import React from "react"

const FacebookIcon = () => (
  <svg className="FacebookIcon" width="9px" height="17px" viewBox="0 0 9 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#FFFFFF">
        <path d="M5.490877,16.6737711 L5.490877,9.4891164 L7.89137,9.4891164 L8.25081,6.6891289 L5.490877,6.6891289 L5.490877,4.9015139 C5.490877,4.0908529 5.714953,3.5384142 6.872097,3.5384142 L8.347979,3.5377663 L8.347979,1.0334351 C8.092731,0.9993126 7.216632,0.9230769 6.197359,0.9230769 C4.069456,0.9230769 2.612636,2.227938 2.612636,4.6242147 L2.612636,6.6891289 L0.20598,6.6891289 L0.20598,9.4891164 L2.612636,9.4891164 L2.612636,16.6737711 L5.490877,16.6737711 Z" />
      </g>
    </g>
  </svg>
)

FacebookIcon.propTypes = {
}

export default FacebookIcon

