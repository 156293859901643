import React from "react"
import { ModalTrigger, Registration } from "components"
import { getQueryParam, isEmpty, internalUrl } from "helpers"

const wrapCTA = (url, title, classes) => {
  const refId = getQueryParam('RefID', url);

  if (!isEmpty(url)) {
    if (url.includes("/register") || url.includes("RefID")) {
      return (
        <ModalTrigger
         classes={`${classes ? classes : ""}`}
         content={<Registration refLink={refId} />}
         modalClass="Registration-wrapper">
          {title}
        </ModalTrigger>
      )
    } else {
      return (
        <a className={`btn ${classes ? classes : ""}`} href={internalUrl(url)}>
          {title}
        </a>
      )
    }
  }
  return
};

export default wrapCTA
