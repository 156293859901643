import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Hero } from "components"
import { isEmpty, wrapCTA } from "helpers"
//import Img from "gatsby-image"


const HeroTitleImageParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  return (
    <Hero homeHero classes="HeroTitleImageParagraph">
      {!isEmpty(data.field_title) && <h1>{data.field_title}</h1>}

      <img
        className="hero-logo-image"
        alt={data.relationships.field_media_image.field_media_image.alt}
        src={
          data.relationships.field_media_image.relationships.field_media_image
            .localFile.childImageSharp.fluid.src
        }
      />

      {
        wrapCTA(data.field_cta.uri, data.field_cta.title, "btn")
      }
  
      {!isEmpty(data.field_body) && (
        <div dangerouslySetInnerHTML={{ __html: data.field_body.value }} />
      )}
    </Hero>
  )
}

HeroTitleImageParagraph.propTypes = {
  id: PropTypes.number.isRequired,
}

export const fragment = graphql`
         fragment HeroTitleImageParagraph on paragraph__hero_title_image {
           drupal_internal__revision_id
           field_body {
             value
           }
           field_cta {
             title
             uri
           }
           relationships {
             field_media_image {
               relationships {
                 field_media_image {
                   localFile {
                     url
                     id
                     publicURL
                     childImageSharp {
                       fluid(maxWidth: 500) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                 }
               }
               field_media_image {
                 alt
               }
             }
           }
         }
       `

export default HeroTitleImageParagraph
