import React from "react"
import { graphql, Link } from "gatsby"
import { CustomLink } from "helpers"
import { GitHubIcon, WebsiteIcon, TwitterIconAuthor, LinkedInIconAuthor } from "components"
import "./AuthorTeaser.scss"

const AuthorTeaser = (props) => {
  const block = "AuthorTeaser"
  const image = props.relationships.field_secblogauthor_image?.relationships?.field_media_image?.localFile?.childImageSharp?.fluid &&
    <div className={`${block}__image`}>
      <img
        alt={props.relationships.field_secblogauthor_image.field_media_image.alt}
        src={props.relationships.field_secblogauthor_image.relationships.field_media_image.localFile.childImageSharp.fluid.src}
      />
    </div>

  const name = props.name &&
    <div className={`${block}__name`}>
      {props.name}
    </div>

  const job = props.job &&
    <div className={`${block}__job`}>
      {props.job}
    </div>

  // Social.
  const social = (props.github || props.twitter || props.linkedin) &&
    <div className={`${block}__social`}>
      {props.website && <CustomLink to={props.website} target="_blank"><WebsiteIcon /></CustomLink>}
      {props.twitter && <CustomLink to={props.twitter} target="_blank"><TwitterIconAuthor /></CustomLink>}
      {props.github && <CustomLink to={props.github} target="_blank"><GitHubIcon /></CustomLink>}
      {props.linkedin && <CustomLink to={props.linkedin} target="_blank"><LinkedInIconAuthor /></CustomLink>}
    </div>

  return (
    <div className={block} key={props.drupal_internal__nid}>
      <Link className={`${block}__link`} to={props.path.alias.replace('/sec.okta.com/', '')}>
        {image}
        {name}
      </Link>
      {social}
      {job}
    </div>
  )
}

export const fragment = graphql`
  fragment AuthorTeaser on node__security_blog_author {
    drupal_internal__nid
    name: title
    github: field_secblogauthor_github
    linkedin: field_secblogauthor_linkedin
    twitter: field_secblogauthor_twitter
    job: field_secblogauthor_title
    website: field_secblogauthor_website
    path {
      alias
    }
    relationships {
      field_secblogauthor_image {
        field_media_image {
          alt
        }
        relationships {
          field_media_image {
            localFile {
              childImageSharp {
               fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AuthorTeaser
