import React from "react"
import PropTypes from "prop-types"
import { FilterCheckbox } from "components"
import "./FilterCheckboxes.scss";

const FilterCheckboxes  = ({ data }) => {
  if (data && data.options.length > 0) {
    return (
      <div className="Filter_Checkboxes">
        <p className="Filter_Checkboxes-title">{data.label}</p>
        <form>
          {
            data.options.map((item, i) => {
              return (
                <FilterCheckbox key={i} item={item} />
              )
            })
          }
        </form>
      </div>
    );
  }

  return null
}

FilterCheckboxes.propTypes = {
  data: PropTypes.object
}
export default FilterCheckboxes
