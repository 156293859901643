import React, { Component } from "react"
import { connect } from "react-redux"
// import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
// import { Container, Nav, NavItem, Logo, PromoBannerWrapper } from "components"
import { Container, Logo, NavItem, PromoBannerWrapper } from "components"
import "./Header.scss";

const breakpoint = 1024;
const scrolldistance = 100;
let winWidth;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavIsOpen: false
    };

    this.headerRef = React.createRef();
  }

  componentDidMount() {
    winWidth = window.innerWidth;
    if (winWidth >= breakpoint) {
      window.addEventListener("scroll", this.throttledScroll, false);
    }

    window.addEventListener("resize", this.throttledResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll, false);
    window.removeEventListener("resize", this.throttledResize, false);
  }

  handleMobileNavTrigger = () => {
    if (winWidth >= breakpoint) {
      const header = this.headerRef.current;
      header.classList.add("no-interaction");

      setTimeout(() => {
        header.classList.remove("no-interaction");
      }, 100)
    }

    if (winWidth <= breakpoint) {
      this.setState({
        mobileNavIsOpen: !this.state.mobileNavIsOpen
      });
    }
  }

  handleScroll = () => {
    const winScroll = window.scrollY;
    const headerRef = this.headerRef.current;
    const hasClass = headerRef?.classList.contains("is-scrolled");

    if (winWidth >= breakpoint) {
      if (winScroll > scrolldistance && !hasClass) {
        headerRef.classList.add("is-scrolled");
      } else if (winScroll < scrolldistance && hasClass) {
        headerRef.classList.remove("is-scrolled");
      }
    }
  }

  throttledScroll = () => {
    window.requestAnimationFrame(this.handleScroll);
  }

  render() {
    const { location, promoBannerIsVisible } = this.props;
    const { mobileNavIsOpen } = this.state;

    return (
      <header className={`Header ${mobileNavIsOpen ? "nav-is-open" : "nav-is-closed"} ${promoBannerIsVisible ? "promobanner-visible" : ""}`} ref={this.headerRef}>
        <Container>
          <div className="Header-inner">
            <div className="Header-logo-menu-wrapper">
              <Logo />
            </div>

            <div className="navItem-wrapper">
              <NavItem href="https://www.okta.com" text="Go to Okta.com ↗" cta />
            </div>
          </div>
        </Container>
      </header>
    );
  }
}

Header.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

const stateToProps = state => ({
  promoBannerIsVisible: state.promoBanner.promoBannerIsVisible
});

const dispatchToProps = {
};

const Connected = connect(stateToProps, dispatchToProps)(
  Header
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = Header;
export default Connected;
