import React from "react"
import "./SocialIcons.scss";
import { FacebookIcon, LinkedInIcon, TwitterIcon } from "components"

const SocialIcons = () => {
  const data = [
    {
      icon: <TwitterIcon />,
      name: "Twitter",
      href: "https://twitter.com/oktadev"
    },
    {
      icon: <FacebookIcon />,
      name: "Facebook",
      href: "https://www.facebook.com/oktadevelopers/"
    },
    {
      icon: <LinkedInIcon />,
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/oktadev/"
    }
  ];

  return (
    <div className="SocialIcons">
      {
        data.map((item, i) => {
          return (
            <div key={i} className={`SocialIcons-item SocialIcons-item-${item.name}`}>
              <a href={item.href} className="SocialIcons-item-link">
                {item.name}
                {item.icon}
              </a>
            </div>
          )
        })
      }
    </div>
  )
}

SocialIcons.propTypes = {
}

export default SocialIcons
