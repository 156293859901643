/***********
 * IMPORTS *
 ***********/
import { createReducer } from "redux-blower";

/***********
 * ACTIONS *
 ***********/
export const SET_SEARCH_TERMS = "SET_SEARCH_TERMS";
export const SET_PARAMETER_SEARCH_TERMS = "SET_PARAMETER_SEARCH_TERMS";

/***********
 * REDUCER *
 ***********/
export default createReducer({
  initialState: { searchTerms: "", parameterSearch: null },

  listenTo: {

    [SET_SEARCH_TERMS]: (state, action) => ({
      ...state,
      searchTerms: action.payload
    }),
    [SET_PARAMETER_SEARCH_TERMS]: (state, action) => ({
      ...state,
      parameterSearch: action.payload
    })
  }
});

/*******************
 * ACTION CREATORS *
 *******************/
export function setSearchTerms(terms) {
  return {
    type: SET_SEARCH_TERMS,
    payload: terms
  };
}

export function setParameterSearchTerms(terms) {
  return {
    type: SET_PARAMETER_SEARCH_TERMS,
    payload: terms
  };
}
