import React from "react"
import PropTypes from "prop-types"
import { Container, HeadingTag } from "components"
import "./SectionHeading.scss";

const SectionHeading = ({ data, body, classes, title, tag }) => {
  if (data.field_title || data.field_body) {
    return (
      <div className={`SectionHeading ${classes ? classes : ""}`}>
        {data.field_title && <HeadingTag classes="SectionHeading-title" data={data} />}
        {data.field_body && <Container><div className="SectionHeading-body" dangerouslySetInnerHTML={{__html: data.field_body.value }} /></Container>}
      </div>
    )
  }

  return null
}

SectionHeading.propTypes = {
  body: PropTypes.object,
  classes: PropTypes.string,
  title: PropTypes.string,
  tag: PropTypes.string,
}

export default SectionHeading
