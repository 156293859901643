import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { colorConverter, internalUrl } from "helpers"
import { CloseIcon } from "components"
import "./PromoBanner.scss";

const PromoBanner = ({ closeBanner, closeBannerKey }, node) => {

  // This is the top promo banner
  const data = useStaticQuery(
    graphql`
      {
        allParagraphTopBanner {
          nodes {
            id
            relationships {
              block_content__security_paragraphs_block {
                id
                relationships {
                  field_paragraph {
                    ... on paragraph__top_banner {
                      id
                      field_text_tablet
                      field_background_color {
                        color
                      }
                      field_text_mobile
                      field_text_desktop
                      field_url {
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (!node.field_url && data.allParagraphTopBanner) {
    const currentNode = data.allParagraphTopBanner.nodes.filter((item) => {
      return item.relationships.block_content__security_paragraphs_block !== null
    })

    node = currentNode[0].relationships.block_content__security_paragraphs_block[0].relationships.field_paragraph[0]
  }

  let backgroundColor =  (node.field_background_color
      ? colorConverter(node.field_background_color.color)
      : "")

  const bannerCopy = <div className="PromoBanner-content">
    <span className="isDesktop">{node.field_text_desktop}</span>
    <span className="isTablet">{node.field_text_tablet}</span>
    <span className="isMobile">{node.field_text_mobile}</span>
  </div>;

  return (
    <div className={`Background-color-${backgroundColor} PromoBanner`}>
      {
        // wrapCTA(node.field_url.uri, bannerCopy)
        <a className="PromoBanner-link" href={internalUrl(node.field_url.uri)}>
          {bannerCopy}
        </a>
      }
      <div className="PromoBanner-close" onClick={closeBanner} aria-label="Close announcement banner" role="button" tabIndex="0" onKeyDown={closeBannerKey}>
        <CloseIcon />
      </div>
    </div>
  )
}

PromoBanner.propTypes = {
  node: PropTypes.object
}

// This is used as a paragraph
export const fragment = graphql`
 fragment PromoBanner on paragraph__top_banner {
   id
   field_text_desktop
   field_background_color {
     color
   }
   field_text_mobile
   field_text_tablet
   field_url {
     uri
   }
 }
`

export default PromoBanner
