import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Container, HeadingTag, Speaker } from "components"
import { isEmpty } from "helpers"
import "./SpeakersParagraph.scss"

const SpeakersParagraph = ({ node, storybookData }) => {
  const data = storybookData || node;
  return (
    <Container>
      <div className="SpeakersParagraph">
        <HeadingTag classes="SpeakersParagraph-title" data={data} circle />
        <div className="Speakers-wrapper">
          {
            !isEmpty(data.relationships) && !isEmpty(data.relationships.field_speakers) && data.relationships.field_speakers.map((speaker, i) => {
              return (
                <Speaker data={speaker} key={i} />
              )
            })
          }
        </div>
      </div>
    </Container>
  )
}

export const fragment = graphql`
  fragment SpeakersParagraph on paragraph__speakers {
    id
    drupal_internal__revision_id
    field_title
    field_title_line_2
    field_header_tag
    relationships {
      field_speakers {
        field_first_name
        field_bio {
          value
        }
        field_display_name
        field_headshot {
          alt
        }
        field_job_title
        field_last_name
        field_photo_creds
        relationships {
          field_headshot {
            localFile {
              url
              id
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            uri {
              url
            }
          }
        }
      }
    }
  }
`

SpeakersParagraph.propTypes = {
  node: PropTypes.object,
  id: PropTypes.string.isRequired,
}

export default SpeakersParagraph
