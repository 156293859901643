import React from "react";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image"; // "gatsby-image" is deprecated, should use gatsby-plugin-image
import { CodeHighlighter } from "components"
import { getStyleObjectFromString } from "../helpers"

const calculateImgWidth = (htmlnode, inlineImage) => {
  if (htmlnode.attribs.width) {
    return `${htmlnode.attribs.width}px`
  }

  if (inlineImage.localFile.childImageSharp.original.width) {
    return `${inlineImage.localFile.childImageSharp.original.width}px`
  }

  return 'auto'
}

const HtmlParser = (content, inlineImages) => {
  const options = {
    decodeEntities: true,
    transform: (htmlnode, key) => {
      if (htmlnode.type === "tag" && htmlnode.name === "code") {
        
        const { data, parent } = htmlnode.children.find(node => {
          if (node.data) return true;
        });

        const language = parent.attribs.class;

        return (
          <CodeHighlighter key={key} data={data} language={language} />
        )
      }
      
      if (htmlnode.type === "tag" && htmlnode.name === "img") {
        const inlineImage = inlineImages.find((inlineImage) => {
          return htmlnode.attribs.src === inlineImage.originalImageUrl;
        });

        if (inlineImage) {
          const alt = htmlnode.attribs.alt ? htmlnode.attribs.alt : ''
          const width = calculateImgWidth(htmlnode, inlineImage)
          const height = htmlnode.attribs.height ? htmlnode.attribs.height : 'auto'
          const objectStyles = htmlnode.attribs.style ? getStyleObjectFromString(htmlnode.attribs.style) : {}
          return (
            <Img
              className={htmlnode.attribs.class}
              alt={alt}
              key={inlineImage.localFile.id}
              style={{ 'width': width, 'maxWidth': '100%', 'margin': '0 auto', 'height': height, position: 'relative', ...objectStyles }}
              fluid={inlineImage.localFile.childImageSharp.fluid}
            />
          );
        }

        return;
      }
      
    },
  };

  return ReactHtmlParser(content, options);
};

export default HtmlParser;
