import React from "react"

const CircleAura = () => (
  <svg className="CircleAura" width="197" height="197" viewBox="0 0 197 197" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M98.5979 196.633C152.831 196.633 196.795 152.668 196.795 98.4355C196.795 44.2027 152.831 0.238281 98.5979 0.238281C44.365 0.238281 0.400635 44.2027 0.400635 98.4355C0.400635 152.668 44.365 196.633 98.5979 196.633ZM98.5976 147.534C125.714 147.534 147.696 125.552 147.696 98.4353C147.696 71.3189 125.714 49.3367 98.5976 49.3367C71.4812 49.3367 49.499 71.3189 49.499 98.4353C49.499 125.552 71.4812 147.534 98.5976 147.534Z" fill="#F89E1C" />
  </svg>
)

CircleAura.propTypes = {
}

export default CircleAura
