import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { internalUrl } from "helpers"
import siteMetaImage from '../../images/oktane20-og.png'
import favicon from "../../images/favicon.png"

function SEO({ metatags, created, updated, path, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaTagDescription = metatags && metatags.description ? metatags.description : false
  const metaDescription = metaTagDescription || description || site.siteMetadata.description
  const siteName = "Okta Security"
  const siteUrl = site.siteMetadata.siteUrl.replace(/\/$/, "")
  const metaImage = siteUrl + siteMetaImage + "?20200130"
  const headline = title
  const siteLanguage = "en"
  const author = "Okta, Inc."

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: metaDescription,
    name: title,
    author: {
      "@type": "Organization",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Organization",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
    },
    datePublished: created,
    dateModified: updated,
    image: {
      "@type": "ImageObject",
      url: `${metaImage}`,
    },
  }

  const schemaOrganization = {
    "@context": "http://schema.org",
    "@type": "Organization",
    legalName: "Okta, Inc.",
    name: "Okta, Inc.",
    url: siteUrl,
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+1 (888) 722-7871",
        contactType: "Customer Service",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (800) 588-1656",
        contactType: "Sales USA",
      },
    ],
    logo: "https://www.okta.com/sites/all/themes/Okta/images/logo.svg",
    sameAs: [
      "https://twitter.com/okta",
      "https://www.facebook.com/OktaIdentity/",
      "https://www.linkedin.com/company/okta-inc-/",
      "https://www.youtube.com/user/OktaInc",
    ],
  }

  return (
    <>
      <Helmet>
        <html lang={siteLanguage} />
        <link rel='icon' href={favicon} />
        {
          title && <title>{title} | {siteName}</title>
        }
        {
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        }
        {
          <script type="application/ld+json">
            {JSON.stringify(schemaOrganization)}
          </script>
        }
      </Helmet>
    </>
  )

}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
