import React from "react"

const LinkedInIcon = () => (
  <svg className="LinkedInIcon" width="17px" height="16px" viewBox="0 0 17 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#FFFFFF">
        <path d="M16.739535,15.4615385 L13.221233,15.4615385 L13.221233,10.3506879 C13.221233,9.0129297 12.670884,8.0996793 11.460678,8.0996793 C10.535006,8.0996793 10.020224,8.7159604 9.780616,9.309899 C9.690763,9.5230838 9.704802,9.8200531 9.704802,10.1170224 L9.704802,15.4615385 L6.219259,15.4615385 C6.219259,15.4615385 6.264186,6.4081647 6.219259,5.5852153 L9.704802,5.5852153 L9.704802,7.1352274 C9.910715,6.4575044 11.024517,5.4902596 12.801919,5.4902596 C15.007059,5.4902596 16.739535,6.9108713 16.739535,9.9699341 L16.739535,15.4615385 L16.739535,15.4615385 Z M2.694405,4.3498604 L2.671942,4.3498604 C1.548781,4.3498604 0.820598,3.5948695 0.820598,2.637865 C0.820598,1.6622418 1.570308,0.9230769 2.715932,0.9230769 C3.860621,0.9230769 4.564468,1.66038 4.586932,2.6350722 C4.586932,3.5920767 3.860621,4.3498604 2.694405,4.3498604 L2.694405,4.3498604 L2.694405,4.3498604 Z M1.222128,5.5852153 L4.324861,5.5852153 L4.324861,15.4615385 L1.222128,15.4615385 L1.222128,5.5852153 L1.222128,5.5852153 Z" />
      </g>
    </g>
  </svg>
)

LinkedInIcon.propTypes = {
}

export default LinkedInIcon

