const formattedMetaTags = (name, slug, bio) => {
  const formattedBio = bio.replace(/<(.|\n)*?>/g, "");
  const href = `https://dev-oktaweb-jsonapi.pantheonsite.io${slug}`;

  const metatagsData = [
    {
      tag: "link",
      attributes: {
        content: null,
        href: href,
        http_equiv: null,
        name: null,
        property: null,
        rel: "canonical",
      },
    },
    {
      tag: "meta",
      attributes: {
        content: "en",
        href: null,
        http_equiv: "content-language",
        name: null,
        property: null,
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: `${name} | Okta Security`,
        href: null,
        http_equiv: null,
        name: "title",
        property: null,
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: formattedBio,
        href: null,
        http_equiv: null,
        name: "description",
        property: null,
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: "website",
        href: null,
        http_equiv: null,
        name: null,
        property: "og:type",
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: href,
        href: null,
        http_equiv: null,
        name: null,
        property: "og:url",
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: name,
        href: null,
        http_equiv: null,
        name: null,
        property: "og:title",
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: formattedBio,
        href: null,
        http_equiv: null,
        name: null,
        property: "og:description",
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: "en",
        href: null,
        http_equiv: null,
        name: null,
        property: "og:locale",
        rel: null,
      },
    },
    {
        tag: "meta",
        attributes: {
          content: "Okta Security",
          href: null,
          http_equiv: null,
          name: null,
          property: "og:site_name",
          rel: null,
        },
      },
    {
      tag: "meta",
      attributes: {
        content: "summary",
        href: null,
        http_equiv: null,
        name: "twitter:card",
        property: null,
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: formattedBio,
        href: null,
        http_equiv: null,
        name: "twitter:description",
        property: null,
        rel: null,
      },
    },
    {
      tag: "meta",
      attributes: {
        content: href,
        href: null,
        http_equiv: null,
        name: "twitter:url",
        property: null,
        rel: null,
      },
    },
    {
      tag: "title",
      attributes: {
        name: "title",
        content: `${name} | Okta Security`,
      },
    },
  ];
  return metatagsData;
};

module.exports = formattedMetaTags;
