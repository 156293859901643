import React, { Component } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "helpers"
import { PlusIcon } from "components"
import "./AccordionSectionItem.scss";

class AccordionSectionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      targetNode: null
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleOpen() {
    const { targetNode } = this.state;

    let content = undefined;
    let closedContent = undefined;

    if (!isEmpty(targetNode)) {
      content = targetNode.querySelector(".Accordion-section-item_body.is-open");
      closedContent = targetNode.querySelector(".Accordion-section-item_body.is-closed");
    }

    if (!isEmpty(content)) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    } else if (!isEmpty(closedContent)) {
      closedContent.style.maxHeight = "0px";
    }
  }

  handleClick(e) {
    let targetElement = null;

    if (e.target.classList.contains("Accordion-section-item_header")) {
      targetElement = e.target.parentNode.parentNode;
      this.setState({
        isOpen: !this.state.isOpen,
        targetNode: targetElement
      }, this.handleOpen);
    } else if (e.target.classList.contains("Accordion-section-item_content")) {
      this.setState({
        isOpen: !this.state.isOpen,
        targetNode: targetElement
      }, this.handleOpen);
    }
    return
  }

  render() {
    const { data } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={`Accordion-section-item ${isOpen ? "is-open" : ""}`}>
        <div role="button" tabIndex="0" aria-label={data.field_title ? `Accordion button for ${data.field_title}` : "Accordion button"} className="Accordion-section-item_content" onClick={this.handleClick} onKeyDown={this.handleKeyPress}>
          <div className="Accordion-section-item_header">
            {data.field_title && <h5 className="Accordion-section-item_title">{data.field_title}</h5>}
            <span className="Accordion-section-item_toggle-icon"><PlusIcon isOpen={isOpen} /></span>
          </div>
          <div className={`Accordion-section-item_body ${isOpen ? "is-open" : "is-closed"}`}>
            <div className="Accordion-section-item_body_content" dangerouslySetInnerHTML={{__html: data.field_body.value}} />
          </div>
        </div>
      </div>
    );
  }
}

AccordionSectionItem.propTypes = {
  data: PropTypes.object
}

export default AccordionSectionItem
