import { createStore } from 'redux';
import rootReducer from './reducer';

export default function configureStore() {
  if (typeof window !== `undefined`) {
    return createStore(
      rootReducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }

  return createStore(
    rootReducer
  );
}
