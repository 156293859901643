/***********
 * IMPORTS *
 ***********/
import { createReducer } from "redux-blower";

/***********
 * ACTIONS *
 ***********/
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const SET_MODAL_CLASS = "SET_MODAL_CLASS";
export const SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY";

/***********
 * REDUCER *
 ***********/
export default createReducer({
  initialState: {
    modalClass: "",
    modalData: null,
    modalIsVisible: false
  },

  listenTo: {

    [SET_MODAL_DATA]: (state, action) => ({
      ...state,
      modalData: action.payload
    }),

    [SET_MODAL_CLASS]: (state, action) => ({
      ...state,
      modalClass: action.payload
    }),

    [SET_MODAL_VISIBILITY]: (state, action) => ({
      ...state,
      modalIsVisible: action.payload
    })
  }
});

/*******************
 * ACTION CREATORS *
 *******************/
export function setModalData(data) {
  return {
    type: SET_MODAL_DATA,
    payload: data
  };
}

export function setModalClass(classnames) {
  return {
    type: SET_MODAL_CLASS,
    payload: classnames
  };
}

export function setModalVisibility(visible) {
  return {
    type: SET_MODAL_VISIBILITY,
    payload: visible
  };
}
