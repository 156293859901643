import React from "react"
import PropTypes from "prop-types"
import "./ModalPhotoBio.scss";

const ModalPhotoBio = ({ classes, image, imageURL, imageALT, name, jobTitle, company, bio }) => {
  return (
    <div className={`ModalPhotoBio ${classes ? classes : ""}`}>
      {image && <div className="ModalPhotoBio-image">
        {image}
      </div>}

      {imageURL && <div className="ModalPhotoBio-image-circle">
        <img src={imageURL} alt={imageALT} />
      </div>}

      <div className="ModalPhotoBio-info">
       {name && <h6 className="ModalPhotoBio-info-name">
          {name}
          </h6>
        }
        {jobTitle && <p className="ModalPhotoBio-info-job">
          {jobTitle}<br />
          {company}
          </p>
        }

        {
          bio && <div className="ModalPhotoBio-info-bio">
          <div dangerouslySetInnerHTML={{__html: bio}} />
        </div>
        }
      </div>
    </div>
  )
}

ModalPhotoBio.propTypes = {
  bio: PropTypes.string,
  classes: PropTypes.string,
  image: PropTypes.object,
  imageURL: PropTypes.string,
  imageALT: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string
}

export default ModalPhotoBio
