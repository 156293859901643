import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "helpers"
import "./HeadingTag.scss"

const HeadingTag = ({ classes, data, circle }) => {
  const Heading = isEmpty(data.field_header_tag) ? "h2" : `${data.field_header_tag}`;

  if (data.field_title) {
    return <Heading className={`Heading-tag
      ${classes ? classes : ""}
      ${circle ? `Heading-tag-circle-${(data.field_header_tag === "h1" || data.field_header_tag === "h2") ? "large" : "normal"}`: ""}`}>
      {data.field_title}
    </Heading>
  }

  return null
}

HeadingTag.propTypes = {
  classes: PropTypes.string,
  circle: PropTypes.bool,
  data: PropTypes.object,
}

export default HeadingTag
