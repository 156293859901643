/* eslint-disable */

/*
  This file contains helper functions for scroll-based animations
*/
const parallaxScroll = {
  animateItem: (parent, elem, start, end, speed = 1, offset = 0) => {
    const height = window.innerHeight;
    const p = parent;

    if (elem && p) {
      const item = elem;
      const parentOffset = p.getBoundingClientRect().top;
      const scrollPercent = ((parentOffset + offset) / height) * speed;
      const updateY = Math.max((start - end) * scrollPercent, end);

      item.style.transform = item.style.webkitTransform = `translate3d(0, ${updateY}px, 0)`;
    }
  },

  fadeItem: (elem, start) => {
    const height = window.innerHeight;
    const winScroll = window.scrollY;

    if (elem) {
      const item = elem;
      const scrollPercent = (winScroll / height);
      const opacityUpdate = start - scrollPercent;

      item.style.opacity = opacityUpdate;
    }
  },

  fadeItemResize: (elem, end) => {
    const width = window.innerWidth;

    if (elem) {
      const item = elem;
      const resizePercent = (width / end);
      const opacityUpdate = resizePercent - 1;
      item.style.opacity = opacityUpdate;
    }
  },

  setItem: (elem, pos) => {
    if (elem) {
      const item = elem;
      item.style.transform = item.style.webkitTransform = `translate3d(0, ${pos}px, 0)`;
    }
  },

  resetItem: (elem) => {
    if (elem) {
      const item = elem;
      item.style.transform = "";
    }
  }

};

module.exports = parallaxScroll;
