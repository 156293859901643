import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Paragraph, SectionHeading } from "components"
import { wrapCTA } from "helpers"

const ColumnsParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;
  const paragraphs = data && data.relationships && data.relationships.paragraphs.map(
    Paragraph
  )

  return (
    <Container>
      <div
        id={`${
          data.field_section_id_anchor
            ? data.field_section_id_anchor
            : ""
        }`}>
        <div>
          <SectionHeading data={data} />
          <div className={`columns flex-parent flex-cols-${data.field_number_of_columns} flex-direction-${data.field_columns_align_items ? data.field_columns_align_items : "left" } flex-content-align-${data.field_align_inner_content ? data.field_align_inner_content : "top"}`}>
            {paragraphs}
          </div>

          {data.field_cta && (
            <div style={{ textAlign: "center" }}>
              {
                wrapCTA(data.field_cta.uri, data.field_cta.title, "btn")
              }
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

ColumnsParagraph.propTypes = {
  id: PropTypes.string.isRequired
}


export const fragment = graphql`
  fragment ColumnsParagraph on paragraph__header_columns_cta {
    id
    drupal_internal__revision_id
    field_number_of_columns
    field_align_inner_content
    field_columns_align_items
    field_section_id_anchor
    field_columns {
      target_revision_id
    }

    relationships {
      paragraphs: field_columns {
         type: __typename
         ...CardParagraph
         ...HTMLParagraph
         ...ImageContentParagraph
         ...MapParagraph
         ...VidyardParagraph
      }
    }
  }
`

export default ColumnsParagraph
