import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { VidyardWrapper } from "components";

const VidyardParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  return (
    <VidyardWrapper id={data.field_vidyard_share_url} />
  )
}

VidyardParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

 export const fragment = graphql`
  fragment VidyardParagraph on paragraph__vidyard {
    drupal_internal__id
    field_vidyard_share_url
  }
`

export default VidyardParagraph
