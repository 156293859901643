import React from "react"
import { Link } from "gatsby"
import "./Logo.scss";

import OktaDeveloperLogo from "../../images/okta.inline.svg"

const Logo = () => (
  <div className="logo-wrapper">
    <a href="/articles">
      <OktaDeveloperLogo /> <span>Security</span>
    </a>
  </div>
)

export default Logo
