import React from "react"
import { graphql } from "gatsby"
import { globalHistory } from "@reach/router"

import { Layout, SEO, Paragraph } from "components"

import curve from "../images/hero/curve-doubleLeft.png"
import MetatagNormalized from "../components/MetatagNormalized/MetatagNormalized";

export default ({ pageContext, data }) => {
  const block = `Page`;

  const seo = <SEO
    path={pageContext}
    metatags={data.nodeSecurityPage.field_metatags}
    title={data.nodeSecurityPage.title}
    created={data.nodeSecurityPage.created}
    updated={data.nodeSecurityPage.revision_timestamp}
  />

  const metatags = <MetatagNormalized
    data={data.nodeSecurityPage.metatag_normalized}
    skipRels={['canonical']}
    replacements={[{
      from: 'https://cms.oktaweb.dev/sec.okta.com',
      to: 'https://sec.okta.com'
    }]}
  />

  const hero_paragraphs = data.nodeSecurityPage.relationships.hero_paragraphs.length > 0 &&
    <div className={`${block}__hero`}>
      {
        data.nodeSecurityPage.relationships.hero_paragraphs.map(
          (item, i) => Paragraph(item, i)
        )
      }
      <div className={`${block}__divider`}>
        <img src={curve} className={`${block}__curve`} alt={``} />
      </div>
    </div>

  const paragraphs = data.nodeSecurityPage.relationships.paragraphs.length > 0 &&
    <div className={`${block}__paragraphs`}>
      {
        data.nodeSecurityPage.relationships.paragraphs.map(
          (item, i) => Paragraph(item, i)
        )
      }
    </div>

  return (
    <Layout
      nid={data.nodeSecurityPage.drupal_internal__nid}
      slug={pageContext.slug}
      location={globalHistory.location.pathname}
    >
      {seo}
      {metatags}
      <div className={block}>
        {hero_paragraphs}
        {paragraphs}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    nodeSecurityPage(path: { alias: { eq: $slug } }) {
      title
      drupal_internal__nid
      revision_timestamp
      created
      metatag_normalized {
        tag
        attributes {
          rel
          name
          href
          content
          http_equiv
          property
        }
      }
      field_metatags {
        title
        description
      }
      field_hero {
        target_revision_id
      }
      field_content {
        target_revision_id
      }
      relationships {
        paragraphs: field_content {
          type: __typename
          ...SecuritySectionParagraph
        }
        hero_paragraphs: field_hero {
          type: __typename
          ...HeroH1HtmlCtaParagraph
          ...HeroTitleImageParagraph
        }
        field_hero {
          __typename
        }
      }
    }
  }
`
