import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types"
import smoothscroll from "smoothscroll-polyfill"
import { Container, AgendaItems, Loading, SearchAndFilterWrapper } from "components";
import { setActiveFilters, setItemsAreDownloaded } from "state/actions/filters";
import { setSearchTerms, setParameterSearchTerms } from "state/actions/search";
import { isEmpty } from "helpers"
import "./AgendaAndFilters.scss";

const AgendaAPIDomain = process.env.AGENDA_API_DOMAIN

/**
 * @class AgendaAndFilter
 */
class AgendaAndFilters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hashExists: false,
      hashValue: "",
      scrollFinished: false,
      items: [],
      parameterFilters: [],
      filters: []
    };

    this.searchWrapperWidth = null;
    this.resizeTimer = null;
  }

  componentDidMount() {
    this.checkForHash();
    if (!this.props.customerPageID) {
      this.handleFiltersRequest();
    }

    this.handleAgendaItemsRequest(this.props.customerPageID);

    this.setupValues();
    this.observerHeader();

    window.addEventListener('scroll', this.handleControlsScroll);
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Don't do this on small screens
    if (window.innerWidth > 1024) {
      this.setSearchElementCSS();
    }
  }

  checkForHash = () => {
    const { hash } = window.location;

    if (hash !== "") {
      const cleanHash = hash.replace("#", "");
      this.setState({
        hashExists: true,
        hashValue: cleanHash
      })
    }
  }

  handleCleanUp = () => {
    this.handleScrollToItem();
    this.props.setItemsAreDownloaded();
  }

  handleScrollToItem = () => {
    const { hashValue } = this.state;

    if (hashValue !== "") {
      const hideLoadingModal = () => {
        this.setState({
          scrollFinished: true
        })
      }

      const scrollToEl = (distance) => {
        smoothscroll.polyfill();
        window.scrollTo({
          top: distance,
          left: 0,
          behavior: 'smooth'
        });

        hideLoadingModal();
      }

      const findEL = () => {
        const elem = document.getElementById(`${hashValue}`);
        if (elem) {
          const offset = elem.getBoundingClientRect().top - 200;
          scrollToEl(offset)
        } else {
          // If agendaID/hash is not found, hide overlay
          hideLoadingModal();
        }
      }

      setTimeout(() => {
        findEL();
      }, 0)
    }
  }

  handleFiltersRequest = () => {
    const options = {
      method: "GET",
      url: `${AgendaAPIDomain}/oktane20api/agenda/filters/`,
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    };

    axios(options).then((response) => {
      this.setState({
        filters: response.data
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        filters: this.props.localFilters
      });
    });
  };


  handleAgendaItemsRequest = (ID) => {
    const options = {
      method: "GET",
      url: `${ID ? `${AgendaAPIDomain}/oktane20api/agenda?customer=${ID}` : `${AgendaAPIDomain}/oktane20api/agenda/`}`,
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    };

    axios(options).then((response) => {
      this.setState({
        items: response.data.results
      }, this.handleCleanUp);
    }).catch((error) => {
      console.log(error);
      this.setState({
        items: this.props.localItems
      }, this.handleCleanUp);
    });
  };

  /**
   * @description Check for the top position of the searchAndFilterWrapper and add/remove a sticky effect
   *
   * @return void
   */
  handleControlsScroll = () => {
    const bodyElement = window.document.body;
    const bodyPosition = window.getComputedStyle(bodyElement).getPropertyValue('position');

    // Don't do this on small screens
    if (window.innerWidth <= 1024) {
      return;
    }

    // If the body has a position don't do this. Example when the modal is open.
    if ('fixed' === bodyPosition) {
      return;
    }

    // DOM Elements
    let agendaItemsElement = window.document.querySelector('.AgendaItems');
    let searchWrapperElement = window.document.querySelector('.SearchAndFilterWrapper');

    if (!isEmpty(agendaItemsElement) && !isEmpty(searchWrapperElement)) {

      // Check for the width of the controls, so we can create the right distance on fixed position
      if (isEmpty(this.searchWrapperWidth)) {
        const ua = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(ua);

        this.searchWrapperWidth  = window.getComputedStyle(searchWrapperElement, null).getPropertyValue('width');

        if (isIE) {
          this.searchWrapperWidth = '310px';
        }
      }

      let agendaItemsElementBoundingRect = agendaItemsElement.getBoundingClientRect();
      let agendaLastItemPadding = 0;

      // Find the position of the window
      let pageHeight = document.documentElement.offsetHeight;
      let windowHeight = window.innerHeight;
      let documentHeight = (document.documentElement && document.documentElement.scrollTop);
      let scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (documentHeight || 0);

      // If the top position of the AgendaItems is less then the header height or AgendaItems height is less then the viewport
      // Remove positioning back to normal (static)
      if (agendaItemsElementBoundingRect.top > this.headerHeight || agendaItemsElementBoundingRect.height < this.viewHeight) {

        searchWrapperElement.classList.remove('is-sticky');
        searchWrapperElement.classList.remove('is-final');
        searchWrapperElement.style.height = `90%`;
        agendaItemsElement.style.marginLeft = 0;

      }

      // Make sure to add the padding if the items on the bottom as high has the viewpot
      if (agendaItemsElementBoundingRect.height > this.viewHeight) {
        agendaLastItemPadding = 48;
      }

      // When we reach the bottom of the page stop the filters and make it sticky we aren't
      if (pageHeight <= windowHeight + scrollPosition + (this.footerElementHeight + 96 - agendaLastItemPadding) && agendaItemsElementBoundingRect.height > this.viewHeight) {
        searchWrapperElement.classList.add('is-final');
        searchWrapperElement.classList.remove('is-sticky');
        agendaItemsElement.style.marginLeft = this.searchWrapperWidth;

      } else if (agendaItemsElementBoundingRect.top <= this.headerHeight && agendaItemsElementBoundingRect.height > this.viewHeight) {
        searchWrapperElement.classList.remove('is-final');
        searchWrapperElement.classList.add('is-sticky');
        searchWrapperElement.style.top = `${this.headerHeight}px`;
        agendaItemsElement.style.marginLeft = this.searchWrapperWidth;
      }
    }
  };

  /**
   * @description Get our initial values
   * @see this.handleControlsScroll()
   *
   * @return void
   */
  setupValues = () => {
    // DOM elements
    const header = window.document.querySelector('.Header');
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const footerElement = window.document.querySelector('.Footer');

    if (!isEmpty(header)) {
      this.headerHeight = header.getBoundingClientRect().height;
    }

    if (!isEmpty(viewHeight)) {
      this.viewHeight = viewHeight;
      this.agendaItemsViewHeight = this.viewHeight - this.headerHeight;
    }

    if (!isEmpty(footerElement)) {
      this.footerElementHeight = this.getAbsoluteHeight(footerElement);
    }
  };

  /**
   * @description Observe to see if the Header element has changed and update our value to natch the new height.
   * Main use case here is if the user has closed the banner in the Header. We will need to update out CSS to match the new height.
   *
   * @return void
   */
  observerHeader = () => {
    const header = window.document.querySelector('.Header');

    const config = {
      childList: true,
      subtree: true,
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver((mutationsList, observer) => {

      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {

          if (!isEmpty(header)) {
            this.headerHeight = header.getBoundingClientRect().height;
            this.agendaItemsViewHeight = this.viewHeight - this.headerHeight;
            this.setSearchElementCSS();
          }

        }
      }
    });

    observer.observe(header, config);
  };

  /**
   * @author Keith Murphy | keith@flickerbox.com
   * @description
   *
   * @param {Element} element
   * @return {number}
   */
  getAbsoluteHeight = (element) => {
    let styles = window.getComputedStyle(element);
    let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

    return Math.ceil(element.offsetHeight + margin);
  };

  /**
   * @description Set the max height for the SearchAndFilterWrapper
   *
   * @return void
   */
  setSearchElementCSS = () => {
    const searchWrapperElement = window.document.querySelector('.SearchAndFilterWrapper');
    const agendaAndFiltersWrapper = window.document.querySelector('.AgendaAndFiltersWrapper');

    if (!isEmpty(searchWrapperElement)) {
      this.maxHeight = this.agendaItemsViewHeight - 45;

      searchWrapperElement.style.maxHeight = `${this.maxHeight}px`;
      searchWrapperElement.style.height = `${this.maxHeight}px`;
      searchWrapperElement.style.overflow = 'auto';
      searchWrapperElement.style.height = '90%';
    }

    if (!isEmpty(agendaAndFiltersWrapper)) {
      let minHeight = this.agendaItemsViewHeight + 60;

      agendaAndFiltersWrapper.style.minHeight = `${minHeight}px`;
    }
  };

  /**
   * @description Clear the sticky/absolute values from the SearchAndFilterWrapper
   *
   * @return void
   */
  clearSearchWrapper = () => {
    const agendaItemsElement = window.document.querySelector('.AgendaItems');
    const searchWrapperElement = window.document.querySelector('.SearchAndFilterWrapper');

    if (!isEmpty(agendaItemsElement) && !isEmpty(searchWrapperElement)) {
      searchWrapperElement.classList.remove('is-final');
      searchWrapperElement.classList.remove('is-sticky');
      agendaItemsElement.style.marginLeft = 0;
      searchWrapperElement.style.maxHeight = 'unset';
      searchWrapperElement.style.height = 'unset';
      searchWrapperElement.style.overflow = 'unset';
    }
  };

  /**
   * @description Make sure the mobile and desktop navs are rendered on screen-size change.
   * @see this.clearSearchWrapper()
   * @see this.handleControlsScroll()
   *
   * @return void
   */
  handleWindowResize = () => {
    const self = this;

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(function() {

      if (window.innerWidth > 1024) {
        // Don't do this on small screens
        self.handleControlsScroll();
        self.setSearchElementCSS();
        self.setupValues();

      } else {
        self.clearSearchWrapper();
      }
    }, 250);
  };

  render() {
    const { items, filters, parameterFilters, hashExists, scrollFinished } = this.state;
    const { itemsAreDownloaded } = this.props;

    return (
      <div className={`AgendaAndFilters ${!this.props.customerPageID ? "AgendaAndFilters-fullpage" : ""}`}>
        {
          hashExists && !scrollFinished && <div className="Loading-wrapper"><Loading /></div>
        }
        { !itemsAreDownloaded && <Loading /> }
        <Container>
          <div className="AgendaAndFiltersWrapper">
            {
              filters.length > 0 && <SearchAndFilterWrapper
                data={filters}
                parameterFilters={parameterFilters}
                classname='searchWrapperElement'
              />
            }

          <AgendaItems
            data={items}
            noMatchMessage="No results found. Please search again." />

          </div>
        </Container>
      </div>
    );
  }
}

AgendaAndFilters.propTypes = {
  itemsAreDownloaded: PropTypes.bool
}

const stateToProps = state => ({
  activeFilters: state.filters.activeFilters,
  itemsAreDownloaded: state.filters.itemsAreDownloaded
});

const dispatchToProps = {
  setActiveFilters,
  setItemsAreDownloaded,
  setSearchTerms,
  setParameterSearchTerms
};

const Connected = connect(stateToProps, dispatchToProps)(
  AgendaAndFilters
);

/***********
 * EXPORTS *
 ***********/
export const Unconnected = AgendaAndFilters;
export default Connected;
