import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import { CloseIcon } from "components"
import { isEmpty } from "helpers"
import { setSearchTerms, setParameterSearchTerms } from "state/actions/search"
import "./Search.scss";

class AgendaSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleHashCheck = this.handleHashCheck.bind(this);
  }

  componentDidMount() {
    this.handleHashCheck();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parameterSearch !== this.props.parameterSearch) {
      this.handleHashCheck();
    }
  }

  handleChange(e, hashValue) {
    let value = hashValue || e.target.value;

    const handleQuery = debounce((query) => {
      this.props.setSearchTerms(query.toLowerCase());

      if (value.length > 0) {
        this.setState({
          isVisible: true
        });
      } else {
        this.setState({
          isVisible: false
        });
      }
    }, 800);

    handleQuery(value);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleHashCheck() {
    if (!isEmpty(this.props.parameterSearch)) {
      this.handleChange(null, this.props.parameterSearch);
    }
  }

  handleClearSearch() {
    this.props.setSearchTerms("");
    this.props.setParameterSearchTerms(null);
    document.querySelector(".Search input").value = "";
    this.setState({
      isVisible: false
    });
  }

  render() {
    const { parameterSearch, title } = this.props;
    const { isVisible } = this.state;
    const valueLabel = isEmpty(parameterSearch) ? title : parameterSearch;

    return (
      <div className="Search">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="Search-items">
            <input name="Search-items" placeholder={valueLabel} type="text" onChange={this.handleChange} />
            <span tabIndex={0} role="button" className={`Search-clear ${isVisible ? "is-visible" : ""}`} onKeyDown={this.handleClearSearch} onClick={this.handleClearSearch}>
              <CloseIcon />
            </span>
          </label>
        </form>
      </div>
    );
  }
}

AgendaSearch.propTypes = {
  activeFilters: PropTypes.array,
  title: PropTypes.string,
  parameterSearch: PropTypes.string
}

const stateToProps = state => ({
  searchTerms: state.search.searchTerms,
  parameterSearch: state.search.parameterSearch
});

const dispatchToProps = {
  setSearchTerms,
  setParameterSearchTerms
};

const Connected = connect(stateToProps, dispatchToProps)(
  AgendaSearch
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = AgendaSearch;
export default Connected;

