import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { colorConverter, wrapCTA } from "helpers"
import { LazyLoader, Paragraph, SectionHeading, SectionLayout } from "components"

const Oktane20SectionParagraph = ({ node, id, storybookData }) => {
  const data = storybookData || node;
  const paragraphs = data.relationships.paragraphs.map(
    Paragraph
  )

  const sectionStyles = {
    backgroundColor: data.field_background_color
      ? colorConverter(data.field_background_color.color)
      : "",
    backgroundOverlay: data.field_background_overlay,
    circlSize: data.field_circle_size,
    circlePosition: data.field_circle_positioning,
    circleOpacity: data.field_circle_opacity,
    circleOffsetHorizontal: data.field_circle_offset_horizontal,
    circleOffsetVertical: data.field_circle_offset_vertical,
    circleStyle: data.field_circle_style,
    headerColor: data.field_header_color
      ? colorConverter(data.field_header_color.color)
      : "",
    paddingBottom: data.field_padding_bottom,
    paddingTop: data.field_padding_top,
    textColor: data.field_text_color
      ? colorConverter(data.field_text_color.color)
      : "",
  }

  return (
    <div className="anchor-section" id={data.field_section_id_anchor ? data.field_section_id_anchor : ""}>
      <LazyLoader fade>
        <SectionLayout container sectionStyles={sectionStyles}>
          <SectionHeading data={data} />
          {paragraphs}
          {data.field_cta && data.field_cta.title && <div className="SectionLayout-main_cta ">{wrapCTA(data.field_cta.uri, data.field_cta.title, "btn")}</div>}
        </SectionLayout>
      </LazyLoader>
    </div>
  )
}

export const fragment = graphql`
  fragment Oktane20SectionParagraph on paragraph__oktane20_section {
    id
    field_title
    field_body {
      value
    }
    drupal_internal__id
    field_background_overlay
    field_circle_offset_horizontal
    field_circle_offset_vertical
    field_circle_opacity
    field_circle_positioning
    field_circle_size
    field_circle_style
    field_background_color {
      color
    }
    field_header_color {
      color
    }
    field_text_color {
      color
    }
    field_padding_bottom
    field_padding_top
    field_header_tag
    field_section_id_anchor
    field_cta {
      title
      uri
    }
    relationships {
      paragraphs: field_columns {
         type: __typename
         ...AccordionSectionParagraph
         ...BlockParagraph
         ...ColumnsParagraph
         ...LogoSetSectionParagraph
         ...MarketoFormParagraph
         ...Oktane20CustomerAgendaParagraph
         ...ReadMoreSectionParagraph
         ...SpeakersParagraph
         ...VidyardParagraph
      }
    }
  }
`

Oktane20SectionParagraph.propTypes = {
  id: PropTypes.string.isRequired
}

export default Oktane20SectionParagraph
