import React, { useRef, useEffect } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { graphql, Link as GatsbyLink } from "gatsby";
import BlogAuthor from "../BlogAuthor/BlogAuthor";
import MetatagNormalized from "../../../components/MetatagNormalized/MetatagNormalized";
import CodeHighlighter from "../../../components/CodeHighlighter/CodeHighlighter";
import { Container, Layout } from "components";
import "./BlogPost.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { formattedMetaTags } from "helpers";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

// change to bloflisttemplate

const BlogPost = ({ data }) => {
  const tableRef = useRef(null);
  const content = data.contentfulSecOktaComBlogPost || [];
  const created = new Date(content.date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const authors = data.contentfulSecOktaComBlogPost.secAuthor;

  if (!content.body) {
    return null;
  }

  const metatags = (
    <MetatagNormalized
      // trim the bio description for SEO purposes.
      data={formattedMetaTags(
        content.title,
        content.slug,
        documentToHtmlString(JSON.parse(content.body.raw)).substring(0, 160)
      )}
      skipRels={["canonical"]}
      replacements={[
        {
          from: "https://cms.oktaweb.dev/sec.okta.com",
          to: "https://sec.okta.com",
        },
      ]}
    />
  );

  const meta = (
    <div className="BlogPost__meta">
      <div className="BlogPost__by">
        <BlogAuthor viewMode={`byline-small`} authors={authors} />
      </div>
    </div>
  );

  const styleBoldTag = (children) => {
    return React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return child;
      }

      if (React.isValidElement(child) && child.type === 'b') {
        return React.cloneElement(
          child,
          {
            style: {
              fontWeight: 700
            },
          },
          styleBoldTag(child.props.children)
        );
      }

      if (React.isValidElement(child)) {
        return React.cloneElement(child, {}, styleBoldTag(child.props.children));
      }

      return child;
    });
  };

  const generateId = (children) => typeof children?.[0] === 'string'
    ? children[0].toLowerCase().replace(/\s+/g, '-')
    : '';

  const options = {
    renderMark: {
      [MARKS.CODE]: (code) =>  (
          <CodeHighlighter language={"bash"} data={code} />
        )
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img src={node.data.target.file.url} alt="" />;
      },
      [BLOCKS.TABLE]: (node, children) => (
        <table
          ref={tableRef}
          style={{
            borderCollapse: "collapse",
            "white-space-collapse": "collapse",
            tableLayout: "fixed",
            overflow: "hidden",
            minWidth: "400px",
            width: "100%",
            verticalAlign: "top",
            textAlign: "left",
            margin: "30px 0",
          }}
        >
          <tbody
            style={{
              verticalAlign: "top",
              textAlign: "left",
            }}
          >
            {children}
          </tbody>
        </table>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
        <th
          style={{
            textAlign: "left",
            verticalAlign: "top",
            whiteSpace: "normal",
            overflow: "hidden",
            wordWrap: "break-word",
            padding: "7px",
          }}
        >
          {styleBoldTag(children)}
        </th>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td
          style={{
            textAlign: "left",
            verticalAlign: "top",
            borderTop: "1px solid #000000",
            padding: "7px",
            maxWidth: "300px",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {children}
        </td>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.HEADING_1]: (node, children) => {
        const id = generateId(children);
        return <h1 id={id}>{children}</h1>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const id = generateId(children);
        return <h2 id={id}>{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const id = generateId(children);
        return <h3 id={id}>{children}</h3>;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const id = generateId(children);
        return <h4 id={id}>{children}</h4>;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const id = generateId(children);
        return <h5 id={id}>{children}</h5>;
      },
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    },
  };

  const output = renderRichText(content.body, options);

  return (
    <Layout variant={`light`}>
      {metatags}
      <Container variant={`narrow`}>
        <div className="BlogPost">
          <div className="BlogPost__container">
            <div className="BlogPost__main">
              <div className="BlogPost__back">
                <GatsbyLink to="/articles">&lsaquo; Back to blog</GatsbyLink>
              </div>
              <time
                className="BlogPost__date"
                dateTime={`${created.getFullYear()}-${`0${created.getMonth() +
                  1}`.substr(-2, 2)}-${`0${created.getDate()}`.substr(-2, 2)}`}
              >
                {months[created.getMonth()]} {created.getDate()},{" "}
                {created.getFullYear()}
              </time>
              <h1 className="BlogPost__title">{content.title}</h1>
              {meta && meta}
              <div className="BlogPost__content">
                <div className="BlogPost__body">
                  <React.Fragment>{output}</React.Fragment>
                </div>
                <div className="BlogPost__authors">
                  <BlogAuthor authors={authors} />
                </div>
              </div>
              <div className="BlogPost__sidebar" />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String) {
    contentfulSecOktaComBlogPost(slug: { eq: $slug }) {
      title
      slug
      date
      updatedAt
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
            }
          }
        }
      }
      secAuthor {
        id
        name
        slug
        jobTitle
        id
        bio {
          bio
        }
        image {
          ... on ContentfulAsset {
            id
            url
          }
          gatsbyImageData(width: 58)
        }
      }
    }
  }
`;
