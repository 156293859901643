import React, { Component } from "react"
import PropTypes from "prop-types"
import { Element, scroller } from "react-scroll"
import { isEmpty } from "helpers"
import { ShowMoreTrigger } from "components"
import "./ShowMore.scss";

class ShowMore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      isOpen: false
    }

    this.showMoreParentRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, false);
    this.handleInit()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize, false);
  }

  handleInit = () => {
    this.setState({
      id: this.generateRandomID()
    })
  }

  generateRandomID = () => {
    const hash = Math.floor(Math.random() * 10000);
    return `ShowMore-${hash}`
  }

  scrollTo = () => {
    scroller.scrollTo(this.state.id, {
      duration: 700,
      delay: 0,
      smooth: true,
      offset: -136,
    })
  }

  handleResize = () => {
    const showMoreParent = this.showMoreParentRef.current;
    const content = showMoreParent && showMoreParent.querySelector(".ShowMore-content.is-open");
    if (content) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    }
  }

  handleOpen() {
    const showMoreParent = this.showMoreParentRef.current;

    let content = undefined;
    let closedContent = undefined;

    if (!isEmpty(showMoreParent)) {
      content = showMoreParent.querySelector(".ShowMore-content.is-open");
      closedContent = showMoreParent.querySelector(".ShowMore-content.is-closed");
    }

    if (!isEmpty(content)) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    } else if (!isEmpty(closedContent)) {
      closedContent.style.maxHeight = "0px";
      this.scrollTo();
    }
  }

  handleClick = (e) => {
    const showMoreParent = this.showMoreParentRef.current;

    this.setState({
      isOpen: !this.state.isOpen,
      targetNode: showMoreParent
    }, this.handleOpen);
  }

  render() {
    const { classes, content, closeText, openText } = this.props;
    const { isOpen, id } = this.state;

    return (
      <div className={`ShowMore ${isOpen ? "is-open" : "is-closed"} ${classes}`} ref={this.showMoreParentRef}>
        <Element name={id} className="element">
          <div className={`ShowMore-content ${isOpen ? "is-open" : "is-closed"}`}>
            <ShowMoreTrigger
              toggleFunction={this.handleClick}
              closeText={closeText}
              openText={openText}
              isOpen={isOpen} />
            {content}
          </div>

          <ShowMoreTrigger
            toggleFunction={this.handleClick}
            closeText={closeText}
            openText={openText}
            isOpen={isOpen} />

        </Element>
      </div>
    )
  }
}

ShowMore.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  closeText: PropTypes.string,
  openText: PropTypes.string,
}

export default ShowMore
