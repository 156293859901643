import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { isEmpty } from "helpers"
import "./PricingItemParagraph.scss"

const PricingItemParagraph = ({ id, storybookData }) => {

  const data = useStaticQuery(
    graphql`
      {
        allParagraphPricingItem {
          nodes {
            drupal_internal__revision_id
            field_active
            field_title
            field_price
            field_body {
              value
            }
          }
        }
      }
    `
  )

  if (!isEmpty(storybookData)) {
    return (
      <div className={`Pricing-module ${storybookData.field_active ? "is-available" : "is-unavailable"}`}>
        <div className="Pricing-module_content">
          <div className="Pricing-module_title-wrapper">
            <h6 className="Pricing-module_title">{storybookData.field_title}</h6>
          </div>
          <div className="Pricing-module_price large-font-size">
            {storybookData.field_price}
          </div>
          <div className="Pricing-module_date" dangerouslySetInnerHTML={{ __html: storybookData.field_body.value }} />
        </div>
      </div>
    );
  }

  const selectedParagraph = data.allParagraphPricingItem.nodes.find((item) => item.drupal_internal__revision_id === id)
  if (!selectedParagraph) {
    return (
      <p style={{ color: `red` }}>Missing Paragraph n°{id}. Bad mapping for CTAParagraph?</p>
    )
  }

  return (
    <div className={`Pricing-module ${selectedParagraph.field_active ? "is-available" : "is-unavailable"}`}>
      <div className="Pricing-module_content">
        <div className="Pricing-module_title-wrapper">
          <h6 className="Pricing-module_title">{selectedParagraph.field_title}</h6>
        </div>
        <div className="Pricing-module_price-wrapper">
          <h1 className="Pricing-module_price large-font-size">{selectedParagraph.field_price}</h1>
        </div>
        <div className="Pricing-module_date" dangerouslySetInnerHTML={{ __html: selectedParagraph.field_body.value }} />
      </div>
    </div>
  )
}

PricingItemParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

export default PricingItemParagraph
