import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Container, Layout, SEO } from "../../../components";

import "./BlogListTemplate.scss";
import BlogPagination from "../BlogPagination/BlogPagination";
import BlogTeaser from "../BlogTeaser/BlogTeaser";

const BlogList = ({ data, pageContext }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const posts = data.allContentfulSecOktaComBlogPost.nodes || [];
  let postsWithoutWebdevExclude = posts.filter(
    (i) => !i.title.startsWith("WEBDEV EXCLUDE")
  );

  // // If it's not staging, only show non-hidden blog posts.
  // if (process.env.GATSBY_IS_STAGING !== '1') { // TODO: we might need to check and add field_hidden in contentful.
  //   postsWithoutWebdevExclude = postsWithoutWebdevExclude.filter(i => i.field_hidden !== true)
  // }

  return (
    <Layout variant={`light`}>
      <SEO
        title={`Articles (Page ${pageContext.currentPage} of ${pageContext.numBlogPages})`}
      />
      <Container variant={`narrow`}>
        <div className={`BlogList`}>
          {postsWithoutWebdevExclude.map((c) => (
            <BlogTeaser {...c} key={c.id} />
          ))}
        </div>
        <BlogPagination path={posts.slug} {...pageContext} />
      </Container>
    </Layout>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query blogList($skip: Int!, $limit: Int!) {
    allContentfulSecOktaComBlogPost(
      sort: { order: DESC, fields: date }
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        updatedAt
        slug
        node_locale
        date
        secAuthor {
          name
          slug
          jobTitle
          id
          bio {
            bio
          }
          image {
            gatsbyImageData(width: 58)
          }
        }
        title
        sys {
          contentType {
            sys {
              id
              linkType
              type
            }
          }
          type
        }
        summary {
          summary
        }
        body {
          raw
        }
      }
    }
  }
`;
