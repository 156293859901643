import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CloseIcon } from "components"
import { setModalData, setModalVisibility } from "state/actions/modal";
import "./Modal.scss";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalWrapper = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if ((this.props.modalIsVisible !== prevProps.modalIsVisible) && this.props.modalIsVisible) {
      const modal = this.modalWrapper.current;
      const scrollPos = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPos}px`;
      modal.addEventListener("touchmove", (e) => {e.stopPropagation()})
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.key === "Escape" && this.props.modalIsVisible === true) {
      this.handleClose();
    }
  }

  handleClose = () => {
    const modal = this.modalWrapper.current;
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.props.setModalVisibility(false);
    this.props.setModalData(null);
    modal.removeEventListener("touchmove", (e) => {e.stopPropagation()})
  }

  render() {
    const { modalData, modalClass, modalIsVisible } = this.props;

    return (
      <div className={`Modal ${modalIsVisible ? "is-visible" : ""} ${modalClass ? modalClass : ""}`} ref={this.modalWrapper}>
        <div className="Modal-wrapper">
          <div className="Modal-close">
            <div className="Modal-close_wrapper">
              <button aria-label="Close modal" className="Modal-close_icon" onClick={this.handleClose}>
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="Modal-content">
            {modalData}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  modalData: PropTypes.object,
  modalIsVisible: PropTypes.bool
}

const stateToProps = state => ({
  modalClass: state.modal.modalClass,
  modalData: state.modal.modalData,
  modalIsVisible: state.modal.modalIsVisible
});

const dispatchToProps = {
  setModalData,
  setModalVisibility
};

const Connected = connect(stateToProps, dispatchToProps)(
  Modal
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = Modal;
export default Connected;
