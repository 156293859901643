import React from "react";
import PropTypes from "prop-types";
import { AgendaItem } from "components"
import "./AgendaTime.scss"

const AgendaTime = ({ data }) => {
  return (
    <div className="AgendaTime">
      <div className="AgendaTime-time">
        <h6>{data.time}</h6>
      </div>

      <div className="AgendaTime-items">
        {
          data.items.map((agenda, i) => {
            return (
              <AgendaItem data={agenda} key={i} />
            )
          })
        }
      </div>
    </div>
  )
}

AgendaTime.propTypes = {
  data: PropTypes.object
}

export default AgendaTime
