import React from "react";
import BlogTeaser from "../BlogTeaser/BlogTeaser";

const BlogAuthorLatestPosts = ({ posts }) => {
  return (
    <div className={`BlogAuthorLatestPosts`}>
      {posts.map((p, i) => (
        <BlogTeaser {...p} key={i} />
      ))}
    </div>
  );
};

export default BlogAuthorLatestPosts;
