const thereAreNoActiveFilters = (arr) => {
  let currentFilters = [];

  arr.forEach((item) => {
    if (item.options.length > 0) {
      currentFilters = currentFilters.concat(item.options[0])
    }
  });

  return currentFilters.length < 1
};

module.exports = thereAreNoActiveFilters;
