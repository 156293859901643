import React from "react"
import PropTypes from "prop-types"

const ShowMoreTrigger = ({ closeText, isOpen, openText, toggleFunction }) => {
  return (
    <div className="ShowMore-trigger-wrapper">
      <button className={`ShowMore-trigger btn btn-outline`} onClick={toggleFunction}>
        {!isOpen && <span>{openText}</span>}
        {isOpen && <span>{closeText}</span>}
      </button>
    </div>
  )
}

ShowMoreTrigger.propTypes = {
  closeText: PropTypes.string,
  isOpen: PropTypes.bool,
  openText: PropTypes.string,
  toggleFunction: PropTypes.func,
}

export default ShowMoreTrigger
