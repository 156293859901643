import React, { Component } from "react"
import PropTypes from "prop-types"

import "./CVENTButton.scss";

class CVENTButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formattedURL: ""
    }

    this.handleCheckURL = this.handleCheckURL.bind(this);
  }

  componentDidMount() {
    this.handleCheckURL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleCheckURL();
    }
  }

  handleCheckURL() {
    const { data, location } = this.props;
    const linkParams = location.search;
    const originalURL = data.uri;

    if (linkParams.toLowerCase().includes("RefID=")) {
      const cventValue = originalURL.includes("?") ? linkParams.replace("?", "") : linkParams;
      const newURL = originalURL.includes("?") ? `${originalURL}&${cventValue}` : `${originalURL}${cventValue}`;

      this.setState({
        formattedURL: newURL
      });
    } else {
      this.setState({
        formattedURL: originalURL
      });
    }
  }

  render() {
    const { data } = this.props;
    const { formattedURL } = this.state;
    return (
      <div>
        <a className="btn" href={formattedURL}>{data.title}</a>
      </div>
    );
  }
}

CVENTButton.propTypes = {
  data: PropTypes.object
}

export default CVENTButton
