const search = (items, searchTerms) => {
  return items.filter((item) => {
    const title = item.title && item.title.toLowerCase();
    const date = item.day && item.day.toLowerCase();
    const body = item.body && item.body.toLowerCase();
    const speakers = item.speakers && item.speakers.filter((speaker) => {
      return speaker.name.toLowerCase().indexOf(searchTerms) >= 0
    })

    // Return any items that the search terms match:
    /*
      title
      speakers
      body copy
      date of event
    */
    return title.indexOf(searchTerms) >= 0 || speakers.length > 0 || body.indexOf(searchTerms) >= 0 || date.indexOf(searchTerms) >= 0;
  });
};

module.exports = search;
