import React, { Component } from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"
import "./FadeIn.scss"

class FadeIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  componentDidMount() {
    this.handleSetState(true);
  }

  componentWillUnmount() {
    this.handleSetState(false);
  }

  handleSetState = (isVisible) => {
    this.setState({
      show: isVisible
    });
  }

  render() {
    const { children, timeout = 500 } = this.props;
    return (
      <CSSTransition
        classNames="fade"
        timeout={timeout}
        in={this.state.show}>
        {children}
      </CSSTransition>
    );
  }
}

FadeIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  timeout: PropTypes.number
};

export const Unconnected = FadeIn;
export default FadeIn;
