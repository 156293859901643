import React from "react"
import PropTypes from "prop-types"
import { Container } from "components";
import "./SectionLayout.scss"

const SectionLayout = ({ children, classes, container, id, sectionStyles }) => {
  const sectionClasses = `${sectionStyles.circlSize && sectionStyles.circlSize === "full" ? `SectionLayout-child-circle-size-${sectionStyles.circlSize}` : ""}
        ${sectionStyles.backgroundColor ? `Background-color-${sectionStyles.backgroundColor}` : "" }
        ${sectionStyles.backgroundOverlay ? `SectionLayout-background-overlay-${sectionStyles.backgroundOverlay}` : ""}
        ${sectionStyles.paddingBottom ? `SectionLayout-padding-bottom-${sectionStyles.paddingBottom}` : ""}
        ${sectionStyles.paddingTop ? `SectionLayout-padding-top-${sectionStyles.paddingTop}` : ""}
        ${sectionStyles.textColor ? `SectionLayout-text-color-${sectionStyles.textColor}` : ""}
        ${sectionStyles.headerColor ? `SectionLayout-header-text-color-${sectionStyles.headerColor}` : ""}`

  return (
    <div id={id} className={`SectionLayout ${sectionClasses} ${classes ? classes : ""}`}>
      {container && <Container><div>{children}</div></Container>}
      {!container && <div>{children}</div>}
    </div>
  )
}

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  container: PropTypes.bool,
  id: PropTypes.string,
  sectionStyles: PropTypes.object
}

export default SectionLayout
