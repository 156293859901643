import React from "react"

const CircleFull = () => (
  <svg className="CircleFull" version="1.1" x="0px" y="0px" viewBox="0 0 2174 2174">
    <circle cx="1087" cy="2174" r="2174" fill="#F89E1C" />
  </svg>
)

CircleFull.propTypes = {
}

export default CircleFull
