import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "helpers"
import { setModalData, setModalClass, setModalVisibility } from "state/actions/modal";

class ModalTrigger extends Component {
  handleKeyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    const { content, modalClass } = this.props;

    if (modalClass) {
      this.props.setModalClass(modalClass)
    } else {
      this.props.setModalClass("")
    }

    this.props.setModalData(content);
    this.props.setModalVisibility(true);
  }

  render() {
    const { classes, children, element } = this.props;
    const Element = isEmpty(element) ? "button" : `${element}`;

    if (Element === "button") {
      return (
        <Element aria-label="Open modal" className={`Modal-trigger ${classes ? classes : ""}`} onClick={this.handleClick}>
          {children}
        </Element>
      )
    }

    return (
      <Element aria-label="Open modal" role="button" tabIndex="0" className={`Modal-trigger ${classes ? classes : ""}`} onClick={this.handleClick} onKeyDown={this.handleKeyPress}>
        {children}
      </Element>
    )
  }
}

ModalTrigger.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ])
}

const stateToProps = state => ({});

const dispatchToProps = {
  setModalData,
  setModalClass,
  setModalVisibility
};

const Connected = connect(stateToProps, dispatchToProps)(
  ModalTrigger
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = ModalTrigger;
export default Connected;
