import React, { Component } from "react"
import PropTypes from "prop-types"
import { ModalPhotoBio, ModalTrigger } from "components"
import "./Speaker.scss";
import Img from "gatsby-image"

class Speaker extends Component {
  render() {
    const { data } = this.props;

    if (!data.relationships.field_headshot) {
      return null
    }

    const modalContent = <ModalPhotoBio
      imageURL={data.relationships.field_headshot.localFile.childImageSharp.fluid.src}
      imageALT={data.field_headshot.alt}
      name={data.field_display_name}
      jobTitle={data.field_job_title}
      bio={data.field_bio && data.field_bio.value} />;

    return (
      <ModalTrigger classes="Speaker" content={modalContent}>
        <div className="Speaker-image">
          <Img
            fluid={
              data.relationships.field_headshot.localFile.childImageSharp.fluid
            }
            alt={data.field_headshot.alt}
          />
        </div>

        <div className="Speaker-bio">
          <h6 className="Speaker-name">{data.field_display_name}</h6>
          <p className="Speaker-info">
            {data.field_job_title}
            <br />
          </p>
        </div>
      </ModalTrigger>
    )
  }
}

Speaker.propTypes = {
  data: PropTypes.object,
}

export default Speaker

