import React from "react"
import PropTypes from "prop-types"
import { NavItem } from "components";
import { isEmpty } from "helpers";
import "./Nav.scss";

const Nav = ({ classes, navitems, location, setNavVisibility }) => {
  return (
    <nav className={`${classes ? classes : ""}`}>
      {
        navitems && navitems.edges.map((item, i) => {
          return (
            <div className="navItem-wrapper" key={i}>
              <NavItem
                href={item.node.relative}
                text={item.node.title}
                cta={item.node.isCTA}
                fragment={item.node.options.fragment}
                isExternal={item.external}
                classes="main-nav-item"
                location={location}
                hasSubmenu={!isEmpty(item.node.below)}
                setNavVisibility={setNavVisibility} />
              {
                item.node.below && <div className="subNav-wrapper">
                  <div className="subNav-innerWrapper">
                    {
                      !isEmpty(item.node.below) && item.node.below.map((childElement, n) => {
                        return (
                          <NavItem
                            href={childElement.relative}
                            isExternal={childElement.external}
                            fragment={childElement.options.fragment}
                            location={location}
                            setNavVisibility={setNavVisibility}
                            text={childElement.title}
                            key={n} />
                        );
                      })
                    }
                  </div>
                </div>
              }
            </div>
          );
        })
      }
    </nav>
  );
}

Nav.propTypes = {
  navitems: PropTypes.object,
}

export default Nav
