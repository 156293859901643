import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { colorConverter, wrapCTA } from "helpers"
import { Paragraph, SectionHeading, SectionLayout } from "components"

const SecuritySectionParagraph = ({ node, id, storybookData }) => {
  const data = storybookData || node;
  const paragraphs = data.relationships.paragraphs.map(
    Paragraph
  )

  const sectionStyles = {
    backgroundColor: data.field_background_color
      ? colorConverter(data.field_background_color.color)
      : "",
    headerColor: data.field_header_color
      ? colorConverter(data.field_header_color.color)
      : "",
    paddingBottom: data.field_padding_bottom,
    paddingTop: data.field_padding_top,
    textColor: data.field_text_color
      ? colorConverter(data.field_text_color.color)
      : "",
  }

  return (
    <SectionLayout container sectionStyles={sectionStyles} id={data.field_section_id_anchor ? data.field_section_id_anchor : ""}>
      <SectionHeading data={data} />
      {paragraphs}
      {data.field_cta && data.field_cta.title && <div className="SectionLayout-main_cta ">{wrapCTA(data.field_cta.uri, data.field_cta.title, "btn")}</div>}
    </SectionLayout>
  )
}

export const fragment = graphql`
  fragment SecuritySectionParagraph on paragraph__security_section {
    id
    field_title
    field_body {
      value
    }
    drupal_internal__id
    field_padding_bottom
    field_padding_top
    field_header_tag
    field_section_id_anchor
    field_cta {
      title
      uri
    }
    relationships {
      paragraphs: field_columns {
         type: __typename
         ...AccordionSectionParagraph
         ...AuthorsParagraph
         ...BlockParagraph
         ...ColumnsParagraph
         ...LatestArticlesParagraph
         ...LogoSetSectionParagraph
         ...MarketoFormParagraph
         ...SpeakersParagraph
      }
    }
  }
`

SecuritySectionParagraph.propTypes = {
  id: PropTypes.string.isRequired
}

export default SecuritySectionParagraph
