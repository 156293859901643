import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AuthorTeaser from "../AuthorTeaser/AuthorTeaser"
import "./Authors.scss"

const Authors = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allNodeSecurityBlogAuthor {
          edges {
            node {
              name: title
              github: field_secblogauthor_github
              linkedin: field_secblogauthor_linkedin
              twitter: field_secblogauthor_twitter
              job: field_secblogauthor_title
              website: field_secblogauthor_website
              drupal_internal__nid
              path {
                alias
              }
              relationships {
                node__security_blog_entry {
                  title
                }
                field_secblogauthor_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                         fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const block = "Authors"
  const authorsFilter = data.allNodeSecurityBlogAuthor.edges.filter(i => i.node.relationships.node__security_blog_entry !== null)
  const authors = authorsFilter.map(i => <AuthorTeaser {...i.node} key={i.node.drupal_internal__nid} />)
  return (
    <div className={block} key={props.drupal_internal__revision_id}>
      <div className={`${block}__items`}>{authors}</div>
    </div>
  )
}

export const fragment = graphql`
  fragment AuthorsParagraph on paragraph__security_blog_authors {
    id
    drupal_internal__revision_id
    relationships {
      authors: field_security_blog_authors {
        ...AuthorTeaser
      }
    }
  }
`

export default Authors
