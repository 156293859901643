import formatStringStyleToCamelCase from "./formatStringStyleToCamelCase"

const getStyleObjectFromString = str => {
    const style = {};
    str.split(";").forEach(el => {
        const [property, value] = el.split(":");
        if (!property) return;

        const formattedProperty = formatStringStyleToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};

export default getStyleObjectFromString
