import React from "react"

import "./PlusIcon.scss"

const PlusIcon = ({ isOpen }) => (
  <svg className="PlusIcon" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <line y1="9" x2="20" y2="9" stroke="white" strokeWidth="2" />
    <path d="M10 3.80104e-08L10 20" stroke="white" strokeWidth="2" className={`PlusIcon-vertical-line ${isOpen ? "is-open" : ""}`} />
  </svg>
)

PlusIcon.propTypes = {
}

export default PlusIcon
