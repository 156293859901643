import React from "react"

const DropDownIcon = () => (
<svg className="DropDownIcon" width="11" height="6" viewBox="0 0 11 6" fill="none">
  <path d="M1.00002 0.499998L5.3302 5L9.66028 0.499999" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)

DropDownIcon.propTypes = {
}

export default DropDownIcon
