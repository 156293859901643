import React from "react";
import PropTypes from "prop-types";
import "./BlogAuthorByline.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogAuthorByline = ({ image, name, viewMode }) => {
  let className = `BlogAuthorByline${
    viewMode ? ` BlogAuthorByline--${viewMode}` : ``
  }`;

  return (
    <div className={className}>
      <div className={`BlogAuthorByline__headshot`}>
        {image && (
          <GatsbyImage
            alt=""
            className={`BlogAuthorByline__image`}
            image={image}
          />
        )}
      </div>
      <div className={`BlogAuthorByline__name`}>{name}</div>
    </div>
  );
};

BlogAuthorByline.propTypes = {
  viewMode: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  name: PropTypes.string.isRequired,
};

export default BlogAuthorByline;
