import React, { Component } from "react"
import PropTypes from "prop-types"
import LazyLoad from "react-lazyload"
import { FadeIn } from "components"

class LazyLoader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: true
    }
  }

  componentDidMount() {
    this.checkForHash();
  }

  checkForHash = () => {
    const { hash } = window.location;

    if (hash !== "") {
      this.setState({
        isActive: false
      })
    }
  }

  render() {
    const { isActive } = this.state;
    const { children, fade, height = 500, offset = 100 } = this.props;

    if (fade && isActive) {
      return (
        <LazyLoad height={height} offset={offset} once>
          <FadeIn>
            {children}
          </FadeIn>
        </LazyLoad>
      )
    }

    if (!fade && isActive) {
      return (
        <LazyLoad height={height} offset={offset} once>
          {children}
        </LazyLoad>
      )
    }

    return (
      <div>
        {children}
      </div>
    )
  }
}

LazyLoader.propTypes = {
  children: PropTypes.object,
  fade: PropTypes.bool,
  height: PropTypes.number,
  offset: PropTypes.number
}

export default LazyLoader
