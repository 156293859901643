import React, { Component } from "react"
import PropTypes from "prop-types"
import Cookies from 'js-cookie'
import { connect } from "react-redux"
import { PromoBanner } from "components"
import { setPromoBannerVisibility } from "state/actions/promoBanner";

class PromoBannerWrapper extends Component {
  constructor(props) {
    super(props)

    this.cookieName = "_okta_oktane_promo-banner_closed"
  }

  componentDidMount() {
    this.checkCookie()
  }

  checkCookie = () => {
    const promoCookie = Cookies.get(this.cookieName);

    if (promoCookie && promoCookie === "true") {
      this.handleHideBanner()
    } else {
      this.handleShowBanner()
    }
  }

  handleShowBanner = () => {
    this.props.setPromoBannerVisibility(true);
  }

  handleHideBanner = () => {
    this.props.setPromoBannerVisibility(false);
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.handleClick();
    }
  }

  handleClick = () => {
    this.handleHideBanner();
    Cookies.set(this.cookieName, "true", { expires: 7 });
  }

  render() {
    const { promoBannerIsVisible } = this.props;
    return (
      <div>
        {promoBannerIsVisible && <PromoBanner closeBanner={this.handleClick} closeBannerKey={this.handleKeyPress} />}
      </div>
    )
  }
}

PromoBannerWrapper.propTypes = {
  promoBannerIsVisible: PropTypes.bool,
  setPromoBannerVisibility: PropTypes.func
}

const stateToProps = state => ({
  promoBannerIsVisible: state.promoBanner.promoBannerIsVisible
});

const dispatchToProps = {
  setPromoBannerVisibility
};

const Connected = connect(stateToProps, dispatchToProps)(
  PromoBannerWrapper
);
/***********
 * EXPORTS *
 ***********/
export const Unconnected = PromoBannerWrapper;
export default Connected;
