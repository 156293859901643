import React from "react"

const CircleSolid = () => (
  <svg className="CircleSolid" width="393" height="376" viewBox="0 0 393 376" fill="none">
    <ellipse cx="196.433" cy="188.435" rx="195.707" ry="187.49" fill="#F08751"/>
  </svg>
)

CircleSolid.propTypes = {
}

export default CircleSolid
