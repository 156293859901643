import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { AgendaAndFilters } from "components"

const Oktane20CustomerAgendaParagraph = ({ node }) => {
  return (
    <div className="Oktane20CustomerAgendaParagraph">
      {
        node.relationships.field_oktane20_customer &&
        <AgendaAndFilters customerPageID={node.relationships.field_oktane20_customer.drupal_internal__tid} />
      }
    </div>
  )
}

export const fragment = graphql`
  fragment Oktane20CustomerAgendaParagraph on paragraph__oktane20_customer_agenda {
    id
    drupal_internal__revision_id
    relationships {
      field_oktane20_customer {
        drupal_internal__tid
      }
    }
  }
`

Oktane20CustomerAgendaParagraph.propTypes = {
  node: PropTypes.object,
  id: PropTypes.string.isRequired,
}

export default Oktane20CustomerAgendaParagraph
