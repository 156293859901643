import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Container, HeadingTag, Sponsor } from "components"
import { isEmpty } from "helpers"
import "./LogoSetSectionParagraph.scss"

const LogoSetSectionParagraph = ({ id, node, storybookData }) => {
  const data = storybookData || node;

  const setColumns = (size) => {
    if (size === "large") {
      return "4"
    }
    if (size === "medium") {
      return "5"
    }

    return "6"
  }

  return (
    <Container>
      <div className="Logo-set-section-paragraph">
          <HeadingTag classes="Logo-set-section-paragraph_title" data={data} circle />
          <div className={`Logo-set-items flex-parent flex-cols-${setColumns(data.field_logo_set_tile_size)} flex-direction-center`}>
            {!isEmpty(data.relationships) && !isEmpty(data.relationships.field_logo_set_items) && (data.relationships.field_logo_set_items || []).map((logo, i) => {
              return (
                <div className={`Logo-set-item-wrapper Logo-set-items_size-${data.field_logo_set_tile_size}`} key={i}>
                  <Sponsor data={logo} />
                </div>
              )
            })}
          </div>
      </div>
    </Container>
  )
}

export const fragment = graphql`
 fragment LogoSetSectionParagraph on paragraph__logo_set_section {
   id
   field_title
   field_header_tag
   field_logo_set_tile_size
   relationships {
     field_logo_set_items {
       id
       field_body {
         value
       }
       relationships {
         field_media_image {
           id
           field_media_image {
             alt
             title
           }
           relationships {
             field_media_image {
               localFile {
                 publicURL
                 childImageSharp {
                   fluid(maxWidth: 100) {
                     ...GatsbyImageSharpFluid
                   }
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`

LogoSetSectionParagraph.propTypes = {
  id: PropTypes.string.isRequired,
}

export default LogoSetSectionParagraph
