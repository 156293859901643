import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import ReactHtmlParser from 'react-html-parser'
import "./HTMLParagraph.scss"

const HTMLParagraph = ({ node, storybookData }) => {
  const data = storybookData || node;
  function transform(htmlnode, index, raw) {
    if (data.localFile && htmlnode.type === "tag" && htmlnode.name === "img") {
      return <Img key={index} fluid={data.localFile.childImageSharp.fluid} />
    }
  }

  const options = {
    decodeEntities: true,
    transform,
  }

  const bodyValue = data.field_body.value

  const checkForIframe = (element) => {
    if (element.includes("iframe")) {
      return (
        <div dangerouslySetInnerHTML={{__html: element }} />
      )
    } else {
      return ReactHtmlParser(element, options)
    }
  }

  const modifiedBodyValue = checkForIframe(bodyValue);

  return (
      <div className="HTML-paragraph">
        <div className="HTML-paragraph_body">
          {modifiedBodyValue}
        </div>
      </div>
  )
}

export const fragment = graphql`
  fragment HTMLParagraph on paragraph__html {
    drupal_internal__revision_id
    field_body {
      value
    }
    localFile {
      url
      id
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
HTMLParagraph.propTypes = {
  id: PropTypes.number.isRequired
}

export default HTMLParagraph
