import React from "react"
import { graphql, Link } from "gatsby"
import Card from "../../../components/Card/Card"
import blogTeaserText from "../blogTeaserText/blogTeaserText"

const BlogCard = (content) => {
  const slug = content.path.alias.replace('/sec.okta.com/', '/')
  const created = new Date(content.created)
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',  'November', 'December'];
  const displayDate = `${months[created.getMonth()]} ${created.getDate()}, ${created.getFullYear()}`

  // Build the teaser.
  if (!content.body) {
    return null;
  }

  const teaser = blogTeaserText({
    text: content.body.summary || content.body.processed,
    maxLength: 150,
  })

  const cardProps = {
    cta: {
      uri: slug,
      title: 'Read more'
    },
    field_title: content.title,
  }

  return <Card {...cardProps} body={teaser} />
}

export const fragment = graphql`
  fragment BlogCard on node__security_blog_entry {
    drupal_internal__nid
    created
    title
    field_hidden
    path {
      alias
    }
    body {
      value
      format
      processed
      summary
    }
  }
`

export default BlogCard
