const internalUrl = url => {
    if (url && url.slug) {
        url = url.slug
    }

    if (url) {

        return url
          .toLowerCase()
          .replace("/sec.okta.com/homepage", "/")
          .replace("internal:", "")
          .replace("/sec.okta.com", "")

    }
    return url;
}

module.exports = internalUrl
